import React, {lazy} from "react";
import Stack from "@mui/material/Stack";

const
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  FccEqualEmploymentOpportunityForm = lazy(() => import("components/ui/forms/equal_employment_opportunity")),
  FccEeoFullReportTable = lazy(() => import("./full_report").then((module) => ({default: module["FccEeoFullReportTable"]})));

export const MultiEeoTables = (props: any) => {
  const
    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload PDF",
        type: "modal",
        modal: {
          modalTitle: "Upload A PDF",
          modalForm: FccEqualEmploymentOpportunityForm,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: []
    };

  return <Stack sx={{padding: "0px !important"}}>
    <hr/>
    <ButtonBar {...buttonBarButons} />
    <FccEeoFullReportTable {...{
      ...props,
      buttonBar: true,
      title: props?.market ? `All ${props?.market?.replace(/%20/gi, " ")} Eeo Files` : `All Eeo Files`,
      market: props?.market ? `${props?.market?.replace(/%20/gi, " ")}` : undefined,
      facility_id: props?.facility_id ? props.facility_id : undefined,
      showFccLink: true
    }} />
  </Stack>;
};