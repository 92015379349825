import React, {lazy, Suspense, useEffect, useState} from "react";
import {useMatch} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAtom} from "jotai/index";
import {cachedRequests} from "services/api/cachedRequests";
import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {FccEeoUploadQueue} from "../components/tables/upload_queue";

const
  Box = lazy(() => import("@mui/material/Box")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found/index"));

export const FccEeoBatchQueue = (props: any) => {
  const
    {cachedMarketsData} = cachedRequests(),
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    [topLevelData, setTopLevelData] = useState<any>(),
    issuesBatchQueuePath = "/equal-employment-opportunity/queue/:id",
    id = useMatch(issuesBatchQueuePath)?.params.id,
    [market, setMarket] = useState(""),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Fetching and using our data
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    issuesSummary = requestAtom(`eeoStatusSummary`, urlPaths.EEO_REPORT_STATUS_SUMMARY, {
      ui_batch_id: id
    }),
    issuesQueue = requestAtom(`eeoUploadQueue`, urlPaths.EEO_REPORT_QUEUE, {
      ui_batch_id: id
    }, setErrorState),

    [issuesSummaryState, setIssuesSummaryState] = useState<any>(() => issuesSummary),
    [tableState, setTableState] = useState<any>(() => issuesQueue),
    [{data: summaryData, isPending: summaryPending, isError: summaryError}] = useAtom<AtomQuery>(issuesSummaryState),
    [{data: tableData, isPending: tablePending, isError: tableError}] = useAtom<AtomQuery>(tableState),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Control states
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    [pastTableData, setPastTableData] = useState<any>(),
    [pageIs404, setPageIs404] = useState<any>(false),
    [refetchButton, setRefetchButton] = useState({display: true, disabled: true}),
    [refetch, setRefetch] = useState(true),
    [getData, setGetData] = useState<any>();

  // Get the markets data once from local storage or the server
  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    setIssuesSummaryState(() => issuesSummary);
    setTableState(() => issuesQueue);
  }, []);

  useEffect(() => {
    const allMarkets = new Set(topLevelData?.market.map((market: { name: string }) => market.name));
    setMarket(Array.from(allMarkets).join(", "));
  }, [topLevelData]);

  useEffect(() => {
    if (summaryError || tableError) {
      setRefetch(false);
      setPageIs404(true);
    }
  }, [summaryError, tableError]);

  // Once summary data is available manipulate it
  useEffect(() => {
    if (summaryData) {
      if (summaryData && summaryData?.summary?.percentage === 100) {
        setRefetch(false);
        setRefetchButton({display: false, disabled: true});
      }

      summaryData.summary.document_name = summaryData?.summary?.candidate_campaign_name;
      setTopLevelData(summaryData?.summary);
      setBreadCrumb(["Home", "equal-employment-opportunity/queue/", `${summaryData.summary.year} EEO Uploads For ${market}`]);
    }
  }, [summaryData]);

  // Everytime getData is reset update the summary table data
  useEffect(() => {
    setTableState(() => issuesQueue);
    if (!pastTableData) {
      setPastTableData(tableData);
    }

    const
      pastTableDataWithNoIds = _.map(pastTableData?.items, (item) => _.omit(item, ["id"])),
      newTableDataWithNoIds = _.map(tableData?.items, (item) => _.omit(item, ["id"]));

    if (pastTableData && !_.isEqual(newTableDataWithNoIds, pastTableDataWithNoIds)) {
      setPastTableData(tableData);
    }
  }, [getData]);

  // Timed loop that executes so long the control state remains False
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let totalTime = 0;
    const loopDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

    if (refetch) {
      setRefetchButton({display: true, disabled: true});
      intervalId = setInterval(() => {
        if (totalTime >= loopDuration) {
          setRefetch(false);
          setRefetchButton({display: true, disabled: false});
          clearInterval(intervalId);
        } else {
          setGetData(new Date().toISOString());
          totalTime += 5000; // Increase total time by 5 seconds
        }
      }, 5000); // 5 seconds interval
    }
    return () => clearInterval(intervalId); // Cleanup on component unmount or control state change
  }, [refetch]);

  return (
    <Suspense fallback={<></>}>
      {!pageIs404 ? <>
        <Title {...{
          topLevelData: {
            screen: "queue",
            ...topLevelData,
            ...summaryData
          },
          title: `${summaryData?.summary?.year} EEO Uploads For ${market}`,
          video: {
            title: "Upload Queue",
            src: "/craft/rest/training/Political-UploadQueue.mp4"
          },
          mobile: props.mobile,
        }} />
        <Breadcrumb {...{
          breadcrumb: breadcrumb
        }} />
        <Box sx={{margin: props.screen ? "10px" : undefined, boxShadow: 2}}>
          <FccEeoUploadQueue {...{
            ...props,
            data: tableData?.items ? tableData.items : undefined,
            showFccLink: false
          }} />
        </Box></> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're unable to locating this upload process."
      }} />}
    </Suspense>
  );
};