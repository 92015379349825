import React, {lazy, Suspense, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FacilityDetails from "components/ui/facilitydetails/index";
import StatusDetails from "pages/status/components/StatusDetails";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import {useAtom} from "jotai";
import {modalContent, modalStateAtom} from "services/jotai/modal";

const
  Stack = lazy(() => import("@mui/material/Stack")),
  Typography = lazy(() => import("@mui/material/Typography")),
  Box = lazy(() => import("@mui/material/Box")),
  Button = lazy(() => import("@mui/material/Button"));

export function Title(props: any) {
  const
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [modal, setModal] = useAtom(modalStateAtom),
    [content, setContent] = useAtom(modalContent),

    Title = styled(Typography)`
        margin: ${props.mobile ? "10px 0 0 0" : "0px"};
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: ${props.mobile ? "1.3rem" : "1.5rem"};
        line-height: 1.334;
        letter-spacing: 0em;
    `,
    Container = styled(Box)`
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        flex-direction: row;
        justify-content: ${props.mobile ? "center" : "space-between"};
            //width: ${props.mobile ? "100%" : undefined};
        margin: ${props.mobile ? "5px 10px 10px 10px" : undefined};
    `;

  const [titleComponent, setTitleComponent] = useState("");
  useEffect(() => {
    console.log("props.mobile", props.mobile);
    setTitleComponent("");
    if (props.topLevelData && props.topLevelData.screen === "facility") {
      setTitleComponent("facility");
    } else if (props.topLevelData && props.topLevelData.screen === "status") {
      setTitleComponent("status");
    } else if (props.topLevelData && props.topLevelData.screen === "queue") {
      setTitleComponent("queue");
    } else if (props.topLevelData && props.topLevelData.screen === "document") {
      setTitleComponent("document");
    } else {
      setTitleComponent("general");
    }
  }, [props]);

  const trainingButton = () => {
    return <Button
      size="small"
      sx={{
        width: props.mobile ? "100px" : "auto",
        alignSelf: props.mobile ? "end" : undefined,
        margin: props.mobile ? "10px 0px 0px 0px" : undefined,
        // width: props.mobile ? "100%" : "auto",
        // textAlign: props.mobile ? "center" : "0px",
        backgroundColor: "rgb(237, 108, 2)",
        color: "white",
        fontSize: "11px",
        padding: "5px 10px",
        boxShadow: 0,
        "&:hover": {
          backgroundColor: "rgb(205,94,3)",
        }
      }}
      endIcon={<SlideshowIcon/>}
      onClick={(e: React.MouseEvent) => {
        setModal(!modal);
        setContent({
          title: props.video.title,
          body: "",
          video: props.video.src
        });
      }}>Training</Button>;
  };

  return (
    <Suspense fallback={<></>}>
      <Container>
        <Stack spacing={0}
          sx={{width: props.mobile ? "100%" : undefined}}
          direction={props.mobile || titleComponent === "status" || titleComponent === "queue" ? "column" : "row"}>
          {props.video && props.mobile ?
            trainingButton() : <></>
          }
          {titleComponent === "facility" ? <FacilityDetails {...{...props.topLevelData}} /> : <></>}
          {titleComponent === "status" || titleComponent === "queue" ? <>
            <Title variant={"h5"}>{props?.title} {props?.topLevelData?.document_name}</Title>
            <StatusDetails {...{...props.topLevelData}} />
          </> : <></>}
          {titleComponent === "document" ? <>
            <Title variant={"h5"}>{props?.title} {props?.topLevelData?.document_name}</Title>
          </> : <></>}
          {titleComponent === "general" ? <Title variant={"h5"}>{props?.title}</Title> : <></>}
        </Stack>
        {props.video && !props.mobile ?
          trainingButton() : <></>
        }
      </Container>
    </Suspense>
  );


}