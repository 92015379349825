import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {NavLink as Link} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarkets} from "services/jotai/requests/requests";
import {v4 as uuidv4} from "uuid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircleIcon from "@mui/icons-material/Circle";

const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  FccEqualEmploymentOpportunityForm = lazy(() => import("components/ui/forms/equal_employment_opportunity")),
  TableCellModalButtonV2 = lazy(() => import("components/ui/table/components/cell/ModalV2").then((module) => ({default: module["TableCellModalButtonV2"]}))),
  BrandTsmHostedLogo = lazy(() => import("components/ui/table/components/cell/BrandTsmHostedLogo").then((module) => ({default: module["BrandTsmHostedLogo"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Button = lazy(() => import("@mui/material/Button")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  CollapseTable = lazy(() => import("components/ui/table/collapseTable").then((module) => ({default: module["CollapseTable"]}))),
  Stack = lazy(() => import("@mui/material/Stack"));

export const FccEeoFullReportTable = (props: any) => {


  const
    [resetPagination, setResetPagination] = useState(0),
    [marketsSelect] = useAtom(allTheMarkets),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {
      getCurrentYear,
      getArrayOfYears,
      convertDate,
      getArrayOfEveryOtherMonth,
      convertDatePretty,
      getCurrentEeoMonth,
      convertEeoMonthToNumber
    } = useDate(),
    {getDocumentQuarter} = useQuarter(),
    [modalData, setModalData] = useState<any>(),
    [modalState, setModalState] = useState<boolean>(false),
    // [compliant, setCompliance] = useState<any>(false),

    [showByMarket, setShowByMarket] = useState<any>(props?.facility_id || props?.market ? false : true),
    [showCompleted, setShowCompleted] = useState<any>(props?.facility_id || props?.market ? true : false),


    [year, setYear] = useState<any>(getCurrentYear()),
    [months, setMonths] = useState<any>(getArrayOfEveryOtherMonth()),
    [month, setMonth] = useState<any>(getCurrentEeoMonth()),
    // [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState(!props.market ? "All Markets" : props.market),
    // [quartersArray, setQuartersArray] = useState(getArrayOfQuarters(year.toString(), true)),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    [marketsArray, setMarketsArray] = useState<string[]>([]),
    // EEO_REPORT
    eeoFilesAtom = requestAtom("eeoFiles", urlPaths.EEO_REPORT, {
      year: year,
      facility_id: props?.facility_id ? props?.facility_id : undefined,
      show_by_market: props?.facility_id ? false : Boolean(showByMarket),
      show_completed: props?.facility_id ? false : Boolean(showCompleted),
      market: market !== "All Markets" && !props.market ? [market] : props.market ? [props.market] : undefined,
      month: Number(convertEeoMonthToNumber(month)),
    }, setErrorState),
    [eeoState, setEeoState] = useState<any>(() => eeoFilesAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(eeoState);

  useEffect(() => {
    setMarket("All Markets");
  }, [month]);

  useEffect(() => {
    if (data && data?.markets?.length > 1 && !props.market) {
      const marketsArr: string[] = data.markets.sort();
      marketsArr.unshift("All Markets");
      setMarketsArray(marketsArr);
    }
    if (props?.market || props?.market && props?.facility_id) {
      setMarketsArray([props.market]);
      setMarket(props.market);
    }
  }, [data]);


  useEffect(() => {
    return setEeoState(eeoFilesAtom);
  }, [year, market, month, showByMarket, showCompleted, isError]);

  useEffect(() => {
    cachedMarketsData();
  }, []);


  const modal = {
      name: "+ Upload PDF",
      type: "modal",
      modal: {
        state: modalState,
        updateModalState: setModalState,
        icon: UploadFileIcon,
        data: modalData,
        modalTitle: "Upload A PDF",
        modalForm: FccEqualEmploymentOpportunityForm,
        modalFooter: {
          modalFooterButtons: {
            left: [{
              variant: "contained",
              color: "secondary",
              name: "cancel",
              type: null,
            }],
            right: [{
              variant: "contained",
              color: null,
              name: "Upload To FCC",
              type: "submit",
            }]
          }
        }
      }
    },
    mobileMarketColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        market: string,
        state: string,
        marketLastUploadDate: string,
        status_fcc: string
        status_blog: string
      }) => {
        return <Grid key={uuidv4()} container spacing={3} sx={{marginTop: "3px", marginLeft: "0px !important"}}>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
              <span><b>Market:</b> {params.market}</span>
              <span><b>State:</b> {params.state}</span>
              <span><b>Updated:</b> {convertDatePretty(props.marketLastUploadDate) || "-"}</span>
              <span><b>FCC Status:</b> {params?.status_fcc === "complete" ?
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "green"}}/> {params.status_fcc}</span> :
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "red"}}/> {params.status_fcc}</span>}</span>
              <span><b>Blog Status:</b> {params?.status_fcc === "complete" ?
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "green"}}/> {params.status_fcc}</span> :
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "red"}}/> {params.status_fcc}</span>}</span>
              <span><b>Upload PDF:</b> <Button
                key={uuidv4()}
                onClick={(() => {
                  setModalData(params);
                  setModalState(!modalState);
                })}><UploadFileIcon
                  sx={{
                    color: "#1976d2",
                    fontSize: "1.6rem",
                  }}/></Button></span>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    marketColumns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      // flex: 1,
      flex: true,
      renderCell: (props: { market: string }) => {
        return <Link to={`/market/${props.market}/`}>{props.market}</Link>;
      }
    }, {
      field: "count_facilities",
      id: "count_facilities",
      headerName: "Facilities",
      flex: true,
      renderCell: (props: { count_facilities: number }) => {
        return props.count_facilities;
      }
    }, {
      field: "count_blogs",
      id: "count_blogs",
      headerName: "Blogs",
      flex: true,
      renderCell: (props: { count_blogs: number }) => {
        return props.count_blogs;
      }
    }, {
      field: "last_upload_date",
      id: "last_upload_date",
      headerName: "Last Upload",
      // flex: 1,
      flex: true,
      renderCell: (props: { last_upload_date: string }) => {
        return props.last_upload_date ? convertDatePretty(props.last_upload_date) : "-";
      }
    }, {
      field: "fcc_status",
      id: "fcc_status",
      headerName: "FCC Status",
      flex: 1,
      renderCell: (params: { fcc_status: string }) => {
        return params?.fcc_status === "complete" ? <p style={{textTransform: "capitalize"}}><CircleIcon
          sx={{color: "green"}}/> {params.fcc_status}</p> : <p style={{textTransform: "capitalize"}}><CircleIcon
          sx={{color: "red"}}/> {params.fcc_status}</p>;
      }
    }, {
      field: "blog_status",
      id: "blog_status",
      headerName: "Blog Status",
      flex: 1,
      renderCell: (params: { blog_status: string }) => {
        return params?.blog_status === "complete" ? <p style={{textTransform: "capitalize"}}><CircleIcon
          sx={{color: "green"}}/> {params.blog_status}</p> : <p style={{textTransform: "capitalize"}}><CircleIcon
          sx={{color: "red"}}/> {params.blog_status}</p>;
      }
    }, {
      field: "actions",
      id: "actions",
      // align: "left",
      // type: "actions",
      headerName: "Actions",
      flex: 1,
      // width: 120,
      // disableSelectionOnClick: true,
      // sortable: false,
      // editable: false,
      // disableColumnMenu: true,
      // disableColumnFilter: true,
      // disableColumnSelector: true,
      // disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return <Button
          key={uuidv4()}
          onClick={(() => {
            setModalData(params);
            setModalState(!modalState);
          })}><UploadFileIcon
            sx={{
              color: "#1976d2",
              fontSize: "1.6rem",
            }}/></Button>;
      },
      //   getActions: (params: any) => {
      //   return [
      //     <GridActionsCellItem
      //       key={uuidv4()}
      //       label={uuidv4()}
      //       icon={<UploadFileIcon sx={{
      //         color: "#1976d2",
      //         // color: "red",
      //         // fillColor: "red",
      //         fontSize: "1.6rem",
      //       }}/>}
      //       onClick={(() => {
      //         // setModalData(params);
      //         // setModalState(!modalState);
      //         console.log("testing");
      //       })}
      //     />
      //   ];
      // }
    }],
    mobileSubTableColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Brands In The Market",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          facId: string,
          calls: string,
          fccDirUrl: string,
          logo: string,
          service: string,
          url: string,
          eeoReportFiles: string,
          blogLastUploadDate: string,
          files: [],
          status_fcc: string,
          status_blog: string
        }
      }) => {
        const data: any = [];
        if (params?.row?.files.length > 0) {
          params?.row?.files.map((doc: {
            upload: { fcc: { file_name: string, create_ts: string } },
            date: string,
            file_id: string,
            name: string
          }) => {

            data.push(<Link
              to={`/equal-employment-opportunity/document/${doc.file_id}`}
              style={{
                color: "#1976d2",
              }}
            >{doc.upload.fcc.file_name.toString()}</Link>);
          });
        } else {
          data.push(<p>No FCC Files</p>);
        }
        return <Grid key={uuidv4()} container spacing={2}
          sx={{marginLeft: "0px !important", padding: "0px !important"}}>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={1} sx={{lineHeight: "normal", marginTop: "0px"}}>

              <BrandTsmHostedLogo {...{
                logo: params.row.logo,
                callsign: params.row.calls,
                facility_id: params.row.facId,
                service: params.row.service
              }} />
              <span><b>Callsign:</b> {params.row.fccDirUrl ?
                <Link to={`/facility/${params.row.facId}/`}>{params.row.calls}</Link> : params.row.calls}</span>
              <span><b>Facility:</b> {params.row.facId ?
                <Link to={`/facility/${params.row.facId}/`}>{params.row.facId}</Link> : "-"}</span>
              <span><b>Url:</b> {params.row.url ?
                <Link target={"_blank"} to={`${params.row.url}`}>{params.row.url}</Link> : "-"}</span>
              <span><b>Updated:</b> {params.row.blogLastUploadDate ? convertDatePretty(params.row.blogLastUploadDate) : "-"}</span>
              <span><b>FCC Status:</b> {!params.row.facId ? "-" : params?.row.status_fcc === "complete" ?
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "green"}}/> {params.row.status_fcc}</span> :
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "red"}}/> {params.row.status_fcc}</span>}</span>
              <span><b>Blog Status:</b> {params?.row.status_blog === "complete" ?
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "green"}}/> {params.row.status_blog}</span> :
                <span style={{textTransform: "capitalize"}}><CircleIcon
                  sx={{color: "red"}}/> {params.row.status_blog}</span>}</span>
              <Stack
                direction="row"
                spacing={1}
              >
                <b>Files:</b>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={-2.5}
                >{data}</Stack>
              </Stack>
              {params.row.facId ? <Button variant="outlined"
                href={params.row.eeoReportFiles}
                sx={{boxShadow: 0, width: "100%"}}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target={"_blank"}
                key={uuidv4()}>
                <FolderIcon/>
                <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC EEO FILES</span>
              </Button> : <></>}
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    subTableColumns = [
      {
        field: "path_type",
        id: "path_type",
        headerName: "Type",
        width: 85,
        renderCell: (params: { row: { path_type: string } }) => {
          return params.row.path_type;
        }
      }, {
        field: "callsign",
        id: "callsign",
        headerName: "Callsign",
        width: 85,
        // flex: 1,
        renderCell: (params: { row: { facility_id: string, callsign: string, fcc_dir_url: string } }) => {
          return (params.row.fcc_dir_url ?
            <Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link> : params.row.callsign);
        }
      }, {
        field: "brand",
        id: "brand",
        headerName: "Brand",
        // flex: 1,
        width: 80,
        sortable: false,
        editable: false,
        disableColumnMenu: true,
        disableColumnFilter: true,
        disableColumnSelector: true,
        renderCell: (params: { row: { logo: string, facility_id: string, service: string, callsign: string } }) => {
          return <BrandTsmHostedLogo {...{
            logo: params.row.logo,
            callsign: params.row.callsign,
            facility_id: params.row.facility_id,
            service: params.row.service
          }} />;
        }

      }, {
        field: "facility_id",
        id: "facility_id",
        headerName: "Facility",
        width: 70,
        renderCell: (params: { row: { facility_id: string } }) => {
          return params.row.facility_id ?
            <Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link> : <></>;
        }
      }, {
        field: "eeoReportFiles",
        id: "eeoReportFiles",
        headerName: "FCC EEO Files",
        width: 180,
        renderCell: (params: {
          row: {
            facility_id: string,
            fcc_folder_links: string,
            path_type: string,
            files: {
              upload: {
                blog: {
                  link: string,
                  eeoLink: string,
                }
              }
            }[]
          }
        }) => {
          if (params.row.path_type === "fcc") {
            return <Button variant="outlined"
              href={params.row.fcc_folder_links}
              sx={{boxShadow: 0, width: "100%"}}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target={"_blank"}
              key={uuidv4()}><FolderIcon/><span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC EEO FILES</span></Button>;
          } else if (params.row.path_type === "blog" && params?.row?.files[0]?.upload?.blog?.eeoLink) {
            return <Button variant="outlined"
              href={params?.row?.files[0]?.upload?.blog?.eeoLink?.replace(".com", ".com/")?.replace(".com//", ".com/")}
              sx={{boxShadow: 0, width: "100%"}}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target={"_blank"}
              key={uuidv4()}><FolderIcon/><span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW EEO BLOG PAGE</span></Button>;
          } else {
            return <></>;
          }
        }
      }, {
        field: "site",
        id: "site",
        headerName: "Blog",
        flex: 1,
        renderCell: (params: { row: { site: string } }) => {
          return params.row.site ? <Link target={"_blank"} to={`${params.row.site}`}>{params.row.site}</Link> : <></>;
        }
      }, {
        field: "last_upload_date",
        id: "last_upload_date",
        headerName: "Last Upload",
        width: 110,
        // flex: 1,
        renderCell: (props: { row: { last_upload_date: string } }) => {
          return props.row.last_upload_date ? convertDatePretty(props.row.last_upload_date) : "-";
        }
      }, {
        field: "documents",
        id: "documents",
        headerName: "Pdf Name/Pdf Link",
        flex: 1,
        align: "left",
        cellClassName: "documents",
        renderCell: (params: { row: { files: [] } }) => {
          const data: any = [];
          if (params?.row?.files.length > 0) {
            params?.row?.files.map((doc: {
              upload_date: string,
              file_id: string,
              name: string,
              path_type: string,
              upload: { blog: { link: string, eeoLink: string, file: { slug: { filename: string } } } }
            }) => {

              if (doc.path_type === "fcc") {
                data.push(<Link
                  to={`/equal-employment-opportunity/document/${doc.file_id}`}
                  style={{
                    color: "#1976d2",
                  }}
                >{doc.name.toString()}</Link>);
              } else if (doc.path_type === "blog") {
                data.push(<Link
                  target={"_blank"}
                  to={`${doc.upload.blog.link}`}
                  style={{
                    color: "#1976d2",
                  }}
                >{doc.upload.blog.file.slug.filename}</Link>);
              }
            });
          } else {
            data.push(<p>No FCC Files</p>);
          }
          return <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={-2.5}
          >{data}</Stack>;
        }
      }, {
        field: "fcc_status",
        id: "fcc_status",
        headerName: "FCC Status",
        width: 140,
        renderCell: (params: { row: { fcc_status: string, path_type: string } }) => {
          return params?.row.fcc_status === "complete" && params.row.path_type === "fcc" ?
            <p style={{textTransform: "capitalize"}}><CircleIcon
              sx={{color: "green"}}/> {params.row.fcc_status}</p> : params.row.path_type === "fcc" ?
              <p style={{textTransform: "capitalize"}}><CircleIcon
                sx={{color: "red"}}/> {params.row.fcc_status}</p> : <></>;
        }
      }, {
        field: "blog_status",
        id: "blog_status",
        headerName: "Blog Status",
        width: 140,
        renderCell: (params: { row: { blog_status: string, path_type: string } }) => {
          return params?.row.blog_status === "complete" && params.row.path_type === "blog" ?
            <p style={{textTransform: "capitalize"}}><CircleIcon
              sx={{color: "green"}}/> {params.row.blog_status}</p> : params.row.path_type === "blog" ?
              <p style={{textTransform: "capitalize"}}><CircleIcon
                sx={{color: "red"}}/> {params.row.blog_status}</p> : <></>;
        }
      }],
    brandsColumns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      }
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 80,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { logo: string, facility_id: string, service: string, callsign: string } }) => {
        return <BrandTsmHostedLogo {...{
          logo: params.row.logo,
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }

    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Callsign",
      width: 80,
      renderCell: (params: { row: { facility_id: string, callsign: string, fcc_folder_links: string } }) => {
        return (params.row.fcc_folder_links ?
          <Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link> : params.row.callsign);

      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 65,
      renderCell: (params: { row: { service: string } }) => {
        return params.row.service;
      }
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility",
      width: 75,
      renderCell: (params: { row: { facility_id: string } }) => {
        const ids = params?.row?.facility_id?.split(",");
        return (params.row.facility_id && ids.length > 0 ?
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexFlow: "wrap",
            }}
          >{ids.map((facility_id: string) => <Link key={uuidv4()}
              to={`/facility/${facility_id.trim()}/`}>{facility_id}</Link>)}</Stack>
          : params.row.facility_id ?
            <Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link> : "-");
      }
    }, {
      field: "path_type",
      id: "path_type",
      headerName: "Type",
      width: 65,
      renderCell: (params: { row: { path_type: string } }) => {
        return params.row.path_type;
      }
    }, {
      field: "site",
      id: "site",
      headerName: "Blog",
      flex: 1,
      renderCell: (params: { row: { site: string } }) => {
        return params.row.site ?
          <Link target={"_blank"} to={`${params.row.site}`}>{params.row.site.split("//")[1]}</Link> : <></>;
      }
    }, {
      field: "last_upload_date",
      id: "last_upload_date",
      headerName: "Last Upload",
      // flex: 1,
      flex: true,
      renderCell: (props: { row: { last_upload_date: string } }) => {
        return props.row.last_upload_date ? convertDatePretty(props.row.last_upload_date) : "-";
      }
    }, {
      field: "documents",
      id: "documents",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      align: "left",
      cellClassName: "documents",
      renderCell: (params: { row: { files: [] } }) => {
        const data: any = [];
        if (params?.row?.files) {
          params?.row?.files.map((doc: {
            upload_date: string,
            file_id: string,
            name: string
          }) => {

            // if (compliant && `Q${getDocumentQuarter(doc.date)}` === quarter && doc.name.includes(".pdf")) {
            //   data.push(<Link to={`/issues-and-programs/document/${doc.file_id}`}
            //     style={{
            //       color: "#1976d2",
            //     }} rel="noreferrer"
            //   >{doc.upload.fcc.file_name.toString()}...hehehe....</Link>);
            // } else if (!compliant && doc.name.includes(".pdf")) {
            //   data.push(<Link to={`/issues-and-programs/document/${doc.file_id}`}
            //     style={{
            //       color: "#1976d2",
            //     }} rel="noreferrer"
            //   >{doc.upload.fcc.file_name.toString()}</Link>);
            // } else {
            data.push(<p>{doc.name.toString()}</p>);
            // }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }, {
      field: "status_fcc",
      id: "status_fcc",
      headerName: "FCC Status",
      flex: 1,
      renderCell: (params: { row: { fcc_status: string, path_type: string } }) => {
        return params?.row.fcc_status === "complete" && params.row.path_type === "fcc" ?
          <p style={{textTransform: "capitalize"}}><CircleIcon
            sx={{color: "green"}}/> {params.row.fcc_status}</p> : params.row.path_type === "fcc" ?
            <p style={{textTransform: "capitalize"}}><CircleIcon
              sx={{color: "red"}}/> {params.row.fcc_status}</p> : <></>;
      }
    }, {
      field: "status_blog",
      id: "status_blog",
      headerName: "Blog Status",
      flex: 1,
      renderCell: (params: { row: { blog_status: string, path_type: string } }) => {
        return params?.row.blog_status === "complete" && params.row.path_type === "blog" ?
          <p style={{textTransform: "capitalize"}}><CircleIcon
            sx={{color: "green"}}/> {params.row.blog_status}</p> : params.row.path_type === "blog" ?
            <p style={{textTransform: "capitalize"}}><CircleIcon
              sx={{color: "red"}}/> {params.row.blog_status}</p> : <></>;
      }
    }],
    mobileBrandsColumns = [{
      field: "status",
      id: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: { row: { url: string } }) => {
        return params.row.url;
      }
    }],

    topperProps: any = {
      title: "Equal Employment Opportunities",
      inputs: [{
        // NOTE: Uploads / completed markets are removed from the view
        // Show files grouped in a collapse table by market OR all documents in a non collapse table
        label: "Show By Market",
        type: "switch",
        defaultValue: false,
        state: showByMarket,
        setState: setShowByMarket
      }, {
        // Show all the completed uploads that were removed from the view
        label: "Show Completed",
        type: "switch",
        defaultValue: false,
        state: showCompleted,
        setState: setShowCompleted
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        width: "85px",
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Months",
        type: "select",
        multiple: false,
        width: "120px",
        options: months,
        defaultValue: getCurrentEeoMonth(),
        state: month,
        setState: setMonth
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "150px",
        options: marketsArray,
        defaultValue: "All Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          // setCompliance(false);
          setShowByMarket(false);
          setShowCompleted(false);
          setMarket("All Markets");
          setYear(getCurrentYear());
          setMonth(getCurrentEeoMonth());
          // setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };


  return (
    <Suspense fallback={<></>}>
      <TableCellModalButtonV2 {...modal} />
      <Topper {...{...topperProps, mobile: props.mobile}} />
      {showByMarket ? <CollapseTable {...{
        tableKey: "eeoTableMarkets",
        mobile: props.mobile,
        data: data?.items && data?.items?.length > 0 ? data?.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileMarketColumns : marketColumns,
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        pending: isPending,
        resetPagination: resetPagination,
        tableSortBy: "market",
        subTableColumns: props.mobile ? mobileSubTableColumns : subTableColumns,
        subTableKey: "items",
        subTableSortBy: "score",
        pageSize: 50
      }} /> : <Table {...{
        tableKey: "eeoTableBrands",
        mobile: props.mobile,
        data: data?.items && data?.items?.length > 0 ? data?.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileBrandsColumns : brandsColumns,
        sortBy: "market",
        tableSortBy: "market",
        sortOrder: "asc",
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        pageSize: 50,
      }} />}

    </Suspense>
  );

};