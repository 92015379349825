import React, {lazy, Suspense, useEffect, useState} from "react";
import {NavLink as Link, useMatch} from "react-router-dom";
import {useApiAuth} from "services/api/useApiAuth";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {v4 as uuidv4} from "uuid";
import {Brand} from "components/ui/table/components/cell/Brand";
import {allTheMarketsData} from "services/jotai/requests/requests";
import {useAtom} from "jotai";
import {cachedRequests} from "services/api/cachedRequests";
import urlPaths from "services/api/urlPaths";
import {MarketUploadsTable} from "pages/fcc_issues_and_programs/components/tables/market_uploads";
import {MultiPoliticalTables} from "pages/fcc_political_candidates_and_issues/components/tables/market_tables";
import {MultiEeoTables} from "pages/fcc_equal_employment_opportunities/components/tables/market_tables";

const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found/index")),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Stack = lazy(() => import("@mui/material/Stack"));


export const Market = (props: any) => {
  const
    {cachedMarketsData} = cachedRequests(),
    {convertDate, getCurrentYear, getArrayOfYears} = useDate(),
    {getDocumentEndOfQuarter, getDocumentQuarter, getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    [allMarketsData] = useAtom(allTheMarketsData),
    [marketBrands, setMarketBrands] = useState([]),
    [tableData, setTableData] = useState<any>([]),
    [year, setYear] = useState(getCurrentYear()),
    [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    // [market, setMarket] = useState("All Markets"),
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [pageIs404, setPageIs404] = useState<any>(false),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    [issuesAndProgramsLink, setIssuesAndProgramsLink] = useState<string>(),
    [topLevelData, setTopLevelData] = useState<any>(),
    [callsign, setCallsign] = useState<any>(),
    marketPath = "/market/:name",
    marketName = useMatch(marketPath)?.params.name,
    {makeRequest} = useApiAuth();

  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    if (allMarketsData.length > 0) {
      allMarketsData.map((market: any) => {
        if (marketName?.replace(/%20/gi, " ") === market.name) {
          // return market.brands;
          setMarketBrands(market.brands);
        }
      });
    }
  }, [allMarketsData]);

  // Make a request to obtain the facilities issues and programs
  useEffect(() => {
    setTableData([]);
    if (marketName) {
      const params: any = {
        year: year,
        market: marketName.replace(/%20/g, " "),
      };
      makeRequest("GET", urlPaths.ISSUES_ENDPONT, null, params).then((data: any) => {
        const tableRows: any = [];
        if (data.items.length > 0) {
          data?.items.forEach((brand: any) => {
            if (brand.documents && brand.documents.length > 0) {
              const document = {
                id: uuidv4(),
                market: brand.market,
                callsign: brand.callsign,
                service: brand.service,
                facility_id: brand.facility_id,
                fcc_directory: brand.fcc_directory,
                containing_folder_link: data.items[0].containing_folder_link,
              };
              brand.documents.forEach((doc: any) => {
                tableRows.push({
                  ...document,
                  id: uuidv4(),
                  name: doc.name,
                  link: doc.link,
                  date: convertDate(doc.date),
                  selector: data.items[0].facility_id + doc.date + doc.link,
                  file_id: doc.file_id,
                });
              });
            }
          });
        }
        setIssuesAndProgramsLink(data.items[0].containing_folder_link);
        setTableData([...tableRows.reverse()]);
        setBreadCrumb(["Home", "markets/", `${marketName?.replace(/%20/g, " ")}`]);
        setTopLevelData(data.items[0]);
        setCallsign(data.items[0].callsign);
        setPageIs404(data.items.length >= 1 ? false : true);
      }).catch((error) => {
        setPageIs404(true);
        console.log("error", error);
      });
    }
  }, [marketName, year, quarter]);


  const
    brandColumnsDesktop = [{
      field: "brand",
      headerName: "Brand",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service,
          size: 45,
        }} />;
      }
    }, {
      field: "callsign",
      headerName: "Callsign",
      flex: 1,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Link
          to={`/facility/${params.row.facility_id}/`}>{params.row.callsign.toString()}</Link>;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }, {
      field: "facility_id",
      headerName: "Facility ID",
      flex: 1,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Stack direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2} sx={{height: "50px"}}><Link
            to={`/facility/${params.row.facility_id}/`}>{params.row?.facility_id.toString()}</Link></Stack>;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }, {
      field: "service",
      headerName: "Service",
      flex: 1,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Stack direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2} sx={{height: "50px"}}>
          <p>{params.row?.service.toString()}</p></Stack>;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }],
    brandColumnsButtons: any = {
      mobile: props.screen,
      left: [],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/"
      }]
    },
    brandColumnTopperProps: any = {
      title: "Brands",
      inputs: []
    };


  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          date: string,
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          date_required: string
        }
      }) => {
        return <Link to={`/facility/${params.row.facility_id}/`}>
          <Grid container spacing={3}>
            <Grid xs={12} sx={{float: "left"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
                <span><strong>PDF Link:</strong> <Link
                  to={`/craft/view/issues-and-programs/document/${params.row.link}`}
                  key={uuidv4()}
                  style={{
                    color: "#1976d2",
                  }}
                >{params.row.name}</Link></span>
                <span><strong>Date:</strong> {convertDate(params.row.date)}</span>
                <span><strong>Quarter:</strong> {getDocumentQuarter(new Date(params.row.date).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                }))}</span>
              </Stack>
            </Grid>
          </Grid>
        </Link>;
      }
    }];


  return (
    <Suspense fallback={<div></div>}>
      {!pageIs404 ? <><Title {...{
        topLevelData: {
          // screen: "facility",
          ...topLevelData
        },
        title: `${marketName?.replace(/%20/gi, " ")}`,
      }} />
      <Breadcrumb {...{
        breadcrumb: breadcrumb
      }} />
      <Stack spacing={3}>

        <div>
          {/* LIST OF BRANDS IN THE MARKET */}
          <ButtonBar {...brandColumnsButtons} />
          <Topper {...{...brandColumnTopperProps, screen: props.screen}} />
          <Table {...{
            mobile: props.screen,
            tableKey: "marketBrandsReport",
            data: marketBrands,
            columns: props.screen ? mobileColumns : brandColumnsDesktop,
            selector: "x"
          }} />
        </div>
        <hr/>
        {/* LIST OF ISSUES DOCUMENTS UPLOADED IN THE MARKET */}
        <MarketUploadsTable {...{
          ...props,
          buttonBar: true,
          title: `${marketName?.replace(/%20/gi, " ")}'s Issues & Programs Uploads`,
          market: `${marketName?.replace(/%20/gi, " ")}`
        }} />
        {/* LIST OF POLITICAL DOCUMENTS UPLOADED IN THE MARKET */}
        <MultiPoliticalTables {...{market: marketName}} />
        {/* LIST OF EEO DOCUMENTS UPLOADED IN THE MARKET */}
        <MultiEeoTables {...{market: marketName}} />
      </Stack>

      </> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're unable to locate the market you're looking for."
      }} />}
    </Suspense>
  );
};

