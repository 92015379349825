import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {NavLink as Link, useNavigate} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {cachedRequests} from "services/api/cachedRequests";
import {screenTopperSettings} from "../../../../services/jotai/political/political";
import {v4 as uuidv4} from "uuid";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Button = lazy(() => import("@mui/material/Button")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Tooltip = lazy(() => import("@mui/material/Tooltip")),
  IconButton = lazy(() => import("@mui/material/IconButton")),
  TerminalIcon = lazy(() => import("@mui/icons-material/Terminal")),
  ReceiptLongIcon = lazy(() => import("@mui/icons-material/ReceiptLong")),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  Brand = lazy(() => import("components/ui/table/components/cell/Brand").then((module) => ({default: module["Brand"]}))),
  BrandTsmHostedLogo = lazy(() => import("components/ui/table/components/cell/BrandTsmHostedLogo").then((module) => ({default: module["BrandTsmHostedLogo"]}))),
  StepperCell = lazy(() => import("components/ui/table/components/cell/Stepper").then((module) => ({default: module["StepperCell"]}))),
  BlogsStepperCell = lazy(() => import("components/ui/table/components/cell/StepperBlogs").then((module) => ({default: module["BlogsStepperCell"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccEeoUploadQueue = (props: any) => {

  const
    navigate = useNavigate(),
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [topperSettings, setScreenTopperSettings]: any = useAtom<any>(screenTopperSettings),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {
      convertDatePretty,
      getCurrentYear,
      getArrayOfYears,
      getCurrentEeoMonth,
      getArrayOfEveryOtherMonth,
      convertEeoMonthToNumber
    } = useDate(),
    [months, setMonths] = useState<any>(getArrayOfEveryOtherMonth()),
    [month, setMonth] = useState<any>(getCurrentEeoMonth()),
    {makeRequest} = useApiAuth(),
    [year, setYear] = useState<any>(topperSettings?.queue?.year ? topperSettings.queue.year : getCurrentYear()),
    [quarter, setQuarter] = useState<any>(topperSettings?.queue?.quarter ? topperSettings.queue.quarter : `Q${getCurrentQuarter()}`),
    [market, setMarket] = useState(topperSettings?.queue?.market ? topperSettings.queue.market : "My Markets"),
    [errorState, setErrorState] = useState(null),
    uploadQueue = requestAtom("eeoUploadQueue", urlPaths.EEO_REPORT_QUEUE, {
      year: year,
      // market: props?.facility_id || props?.uploaded_by ? undefined : props?.market ? [props.market] : market === "My Markets" ? allUserMarkets : [market], /*market,*/
      month: Number(convertEeoMonthToNumber(month)),
      // facility_id: props?.market || props?.uploaded_by ? undefined : props?.facility_id ? props.facility_id : undefined,
      // uploaded_by: props?.uploaded_by ? props.uploaded_by : undefined,
      // date_based_quarter: quarter
    }, setErrorState),
    [atomState, setAtomState] = useState(() => uploadQueue),
    [{data, isPending, isError}] = useAtom<AtomQuery>(atomState);

  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    const tempData: any = screenTopperSettings;
    tempData.queue = {
      year: year,
      quarter: quarter,
      // market: market,
      month: month,
    };
    setScreenTopperSettings(tempData);
    return setAtomState(uploadQueue);
  }, [year, month, quarter, allUserMarkets]);

  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          upload_filename: string,
          facility_id: string,
          blog_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          date_required: string,
          file_id: string,
          upload_status: string,
          ui_batch_id: string
        }
      }) => {
        return <Stack direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{width: "100%", minHeight: "100%", height: "max-content"}}
        >
          <Grid container spacing={3} sx={{width: "100%", minHeight: "100%", height: "max-content"}}>
            <Grid xs={2}>
              <Stack direction="column"
                justifyContent="center"
                spacing={0}
                sx={{width: "100%", minHeight: "100%", height: "max-content"}}
              >
                <Brand {...{
                  callsign: params.row.callsign,
                  facility_id: params.row.facility_id,
                  service: params.row.service,
                  size: 50,
                  styles: {}
                }}
                />
              </Stack>
            </Grid>
            <Grid xs={10}>
              <Stack direction="column"
                justifyContent="center"
                spacing={-3}
                sx={{marginTop: "-20px", width: "100%", minHeight: "100%", height: "max-content"}}
              >
                <p><strong>Uploaded:</strong> {convertDatePretty(params.row.upload_date)}</p>
                <p><strong>Market:</strong> <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link></p>
                <p><strong>Callsign:</strong> <Link
                  to={`/blog/${params.row.facility_id}/`}>{params.row.callsign}</Link></p>
                <p><strong>Status:</strong> {params.row.upload_status}</p>
                <p style={{marginTop: "-5px", height: "20px", marginBottom: "20px"}}>
                  <StepperCell {...{status: params.row.upload_status}} /></p>
                <p style={{display: "inherit"}}><strong>View Doc: </strong><Link
                  to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/equal-employment-opportunity/document/${params.row.file_id}` : ""}
                  key={params.row?.upload_filename}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "calc(70%)",
                    pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
                    color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
                  }} rel="noreferrer"
                > {params.row?.upload_filename.toString()}</Link></p>
              </Stack>
            </Grid>
            {props.data ? <Stack key={uuidv4()}
              sx={{width: "100%", minWidth: "fit-content", margin: "-5px 0px 5px 0px"}}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}>
              <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                variant={"contained"}
                endIcon={<TerminalIcon/>}
                onClick={(() => {
                  navigate(`/equal-employment-opportunity/queue/${params.row.ui_batch_id}`);
                })}
              >BATCH LOG</Button>
              <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                variant={"contained"}
                endIcon={<ReceiptLongIcon/>}
                onClick={(() => {
                  navigate(`/equal-employment-opportunity/queue/${params.row.ui_batch_id}/blog/${params.row.blog_id}`);
                })}
              >BRAND LOG</Button>
            </Stack> : <></>}
          </Grid>
        </Stack>;
      }
    }],
    baseDesktopColumns = [{
      field: "upload_date",
      id: "upload_date",
      width: 110,
      headerName: "Uploaded",
      renderCell: (params: { row: { upload_date: string } }) => {
        return String(convertDatePretty(params.row.upload_date));
      },
      // flex: 1,
    }, {
      field: "market",
      id: "market",
      headerName: "Market",
      width: 100,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      // flex: 1,
      width: 70,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { logo: string, facility_id: string, service: string, callsign: string } }) => {
        return <BrandTsmHostedLogo {...{
          logo: params.row.facility_id ? undefined : params.row.logo,
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 90,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return params.row.facility_id !== "no-facility" ?
          <Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link> : params.row.callsign;
      }
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility",
      width: 80,
      // flex: 1,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return params?.row?.facility_id !== "no-facility" ?
          <Link
            to={`/facility/${params?.row?.facility_id}/`}>{params?.row?.facility_id}</Link> : params?.row?.facility_id;
      }
    }, {
      field: "path_type",
      id: "type",
      align: "left",
      headerName: "Type",
      width: 60,
      renderCell: (params: { row: { path_type: string } }) => {
        return params?.row?.path_type;
      }
    }, {
      field: "blog_id",
      id: "blog_id",
      headerName: "Blog",
      align: "center",
      width: 40,
      renderCell: (params: { row: { blog_id: string, eeoPageLink: string } }) => {
        return params?.row?.blog_id ?
          <Link target={"_blank"}
            to={`${params.row?.eeoPageLink?.replace(".com", ".com/")?.replace(".com//", ".com/")}`}>{params?.row?.blog_id}</Link> : "-";
      }
    }, {
      field: "uploaded_by",
      id: "uploaded_by",
      align: "left",
      headerName: "Uploader",
      width: 120,
      renderCell: (params: { row: { uploaded_by: string } }) => {
        return params?.row?.uploaded_by ?
          <p
            style={{textTransform: "capitalize"}}>{params?.row?.uploaded_by.split("@")[0]?.replace(".", " ")}</p> : null;
      }
    }, {
      field: "name",
      id: "name",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      cellClassName: "name",
      renderCell: (params: {
        row: { link: string, file_id: string, upload_filename: string, upload_status: string }
      }) => {
        if (params?.row?.upload_filename?.toString()?.includes(".pdf") && params?.row?.upload_status?.toLowerCase() === "verification completed") {
          return <Link
            to={params?.row?.link && params?.row?.link !== "#" && params?.row?.link?.length > 0 ? `/equal-employment-opportunity/document/${params?.row?.file_id}` : ""}
            target={"_blank"}
            key={params.row?.upload_filename}

            style={{
              pointerEvents: params?.row?.link && params?.row?.link !== "#" && params?.row?.link?.length > 0 ? "auto" : "none",
              color: params?.row?.link && params?.row?.link !== "#" && params?.row?.link?.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
            }} rel="noreferrer"
          >{params?.row?.upload_filename?.toString()}</Link>;

        } else if (params?.row?.link?.toString()?.includes("townsquare.media")) {
          return <Link
            to={params.row?.link}
            target={"_blank"}
            key={params.row?.link}
            style={{
              pointerEvents: params.row?.link && params.row?.link !== "#" && params.row?.link?.length > 0 ? "auto" : "none",
              color: params.row?.link && params.row?.link !== "#" && params.row?.link?.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
            }} rel="noreferrer"
          >{params.row?.upload_filename?.toString()}</Link>;
        } else {
          return <p>{params.row?.upload_filename?.toString()}</p>;
        }
      }
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 1,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return params?.row?.upload_status;
      }
    }, {
      field: "step",
      id: "step",
      align: "center",
      headerName: "FCC Steps",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      flex: 2,
      renderCell: (params: {
        row: { path_type: string, upload_status: string }
      }) => {
        return params.row?.path_type === "fcc" ?
          <StepperCell {...{status: params?.row?.upload_status}} /> : <>-</>;
      }
    }, {
      field: "blogstep",
      id: "blogstep",
      align: "center",
      headerName: "Blog Steps",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      flex: 1,
      renderCell: (params: { row: { path_type: string, upload_status: string } }) => {
        return params.row?.path_type === "blog" ?
          <BlogsStepperCell {...{status: params?.row?.upload_status}} /> : <>-</>;
      }
    }],
    desktopColumns = [
      ...baseDesktopColumns,
      ...(!props.data ? [{
        field: "actions",
        id: "actions",
        align: "left",
        headerName: "Actions",
        sortable: false,
        editable: false,
        disableColumnMenu: true,
        disableColumnFilter: true,
        disableColumnSelector: true,
        width: 90,
        renderCell: (params: {
          row: { callsign: string, ui_batch_id: string, blog_id: string, upload_status: string, file_id: string }
        }) => {
          return <Stack direction="row" spacing={0}>
            <Link
              to={`/equal-employment-opportunity/queue/${params.row.ui_batch_id}`}
            >
              <Tooltip
                title="Batch Upload Log"
                enterDelay={1200}
                // leaveDelay={500}
                TransitionProps={{timeout: 200}}
              >
                <IconButton sx={{
                  color: "#1976d2",
                }}>
                  <TerminalIcon/>
                </IconButton>
              </Tooltip></Link>
            {/*<Link*/}
            {/*  to={`/equal-employment-opportunity/queue/${params.row.ui_batch_id}/blog/${params.row?.blog_id}/document/${params.row.file_id}`}*/}
            {/*>*/}
            {/*  <Tooltip*/}
            {/*    title={`Upload For ${params.row.callsign}`}*/}
            {/*    enterDelay={1200}*/}
            {/*      // leaveDelay={500}*/}
            {/*    TransitionProps={{timeout: 200}}*/}
            {/*  >*/}
            {/*    <IconButton sx={{*/}
            {/*      color: "#1976d2",*/}
            {/*    }}>*/}
            {/*      <ReceiptLongIcon/>*/}
            {/*    </IconButton>*/}
            {/*  </Tooltip>*/}
            {/*</Link>*/}
          </Stack>;
        }
      }] : []),
    ],
    topperProps: any = {
      title: props?.title ? props.title : props.mobile ? "Upload Queue" : "Equal Employment Opportunities Upload Queue",
      inputs: [{
        label: <span style={{fontSize: "10px"}}><FolderIcon
          sx={{marginRight: "5px", marginTop: "-5px"}}/>VIEW FCC EEO FILES</span>,
        type: "button",
        display: props?.showFccLink && data && data?.base_folder_link ? props.base_folder_link : false,
        onClick: ((e: React.MouseEvent) => {
          window.open(data?.base_folder_link);
        }),
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        options: getArrayOfYears(),
        defaultValue: topperSettings?.queue?.year ? topperSettings.queue.year : getCurrentYear(),
        state: year,
        setState: setYear,
        width: "90px",
        display: props.data ? false : true,
      }, {
        label: "Months",
        type: "select",
        multiple: false,
        width: "120px",
        options: months,
        defaultValue: getCurrentEeoMonth(),
        state: month,
        setState: setMonth,
        display: props.data ? false : true,
      }
        // , {
        //   label: "Market",
        //   type: "select",
        //   multiple: false,
        //   options: marketsSelect,
        //   defaultValue: topperSettings?.queue?.market ? topperSettings.queue.market : "My Markets",
        //   state: market,
        //   setState: setMarket,
        //   display: props?.market || props?.facility_id || props?.uploaded_by ? false : true,
        // }
      , {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setScreenTopperSettings({
            ...screenTopperSettings,
            "queue": {}
          });

          setMonth(getCurrentEeoMonth());
            // setMarket(props?.market ? props.market : "My Markets");
          setYear(getCurrentYear());
        }),
        display: props.data ? false : true,

      }
      ]
    };

  return (
    <Suspense fallback={<></>}>
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        ...props,
        mobile: props.mobile,
        tableKey: props?.title ? props.title : "politicalUploadQueue",
        data: props.data ? props.data : data && data?.items.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : desktopColumns,
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        minHeight: data && data?.items.length > 0 ? "400px" : undefined
      }} />
    </Suspense>
  );
};