import React, {lazy, Suspense, useEffect, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useStyles} from "./styles/styles.js";
import urlPaths from "services/api/urlPaths";
import {settings} from "services/jotai/settings/settings";
import PolicyIcon from "@mui/icons-material/Policy";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ListItem from "@mui/material/ListItem";
import InsightsIcon from "@mui/icons-material/Insights";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {NavLink as Link} from "react-router-dom";
import GiteIcon from "@mui/icons-material/Gite";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import {useAtom} from "jotai";
import {allUserProfileSettings, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import Badge from "@mui/material/Badge";
// import PaidIcon from "@mui/icons-material/Paid";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import {SvgIconComponent} from "@mui/icons-material";

interface MenuItem {
  name: string;
  src: string;
  href: string;
  active: boolean;
  icon: SvgIconComponent;
  subNav?: SubNavItem[];  // optional
}

interface SubNavItem {
  name: string;
  src: string; // or 'internal' | 'external'
  href: string;
  active: boolean;
  icon: SvgIconComponent;
  badge?: number;
  indent?: boolean;
}

const
  Collapse = lazy(() => import("@mui/material/Collapse")),
  Box = lazy(() => import("@mui/material/Box")),
  Drawer = lazy(() => import("@mui/material/Drawer")),
  List = lazy(() => import("@mui/material/List")),
  Divider = lazy(() => import("@mui/material/Divider")),
  ListItemButton = lazy(() => import("@mui/material/ListItemButton")),
  ListItemIcon = lazy(() => import("@mui/material/ListItemIcon")),
  ListItemText = lazy(() => import("@mui/material/ListItemText"));

export const LeftNav = (props: any) => {
  const
    theme = useTheme(),
    classes = useStyles(),
    {makeRequest} = useApiAuth(),
    [open, setOpen] = useState(true),
    [globalSettings] = useAtom<any>(settings),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [allUserSettings, setAllUserSettings] = useAtom<any>(allUserProfileSettings),
    [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [politicalMatchingCount, setPoliticalMatchingCount] = useState(0),
    [timer, setTimer] = useState(true),
    {getCurrentQuarter} = useQuarter(),
    {getCurrentYear} = useDate(),
    [path, setPath] = useState("/");

  const getPoliticalCompliance = async () => {
    const markets = allUserMarkets.map((item: any, index: number) => `market[${index}]=${encodeURIComponent(item)}`).join("&");
    if (allUserMarkets.length > 0) {
      await makeRequest("GET", `${urlPaths.POLITICAL_MATCHES}?year=${getCurrentYear()}&${markets}&quarter=${getCurrentQuarter()}&matched=false&showLatest=true`).then((data: any) => {
        setPoliticalMatchingCount(data.count);
      }).catch((error) => console.log("error", error));
    }
  };

  useEffect(() => {
    const logic = allUserSettings?.access?.includes("Political") && globalSettings?.menu?.political && globalSettings?.menu?.political?.value;
    if (timer && logic) {
      getPoliticalCompliance();
      setTimer(false);

      setTimeout(() => {
        setTimer(true);
      }, 60000);
    }
  }, [allUserMarkets, timer]);

  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };

  useEffect(() => {
    setPath(props.route);
    console.log("props change", props);
  }, [props]);

  useEffect(() => {
    console.log("globalSettings", globalSettings);

  }, [globalSettings]);

  const
    MainMenuItems: MenuItem[] = [{
      name: "Dashboard",
      src: "internal",
      href: "/",
      active: true,
      icon: InsightsIcon
    }, {
      name: "Townsquare",
      src: "internal",
      href: "/townsquare/",
      active: true,
      icon: GiteIcon,
      subNav: [{
        name: "Markets",
        src: "internal",
        href: "/markets/",
        active: true,
        icon: HorizontalRuleIcon
      }, {
        name: "Brands",
        src: "internal",
        href: "/facilities/",
        active: true,
        icon: HorizontalRuleIcon
      }]
    }, {
      name: "Political",
      src: "internal",
      href: "/political-candidates-and-issues/",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access?.includes("Political") && globalSettings?.menu?.political && globalSettings?.menu?.political?.value || allUserSettings?.access?.includes("Political") && props.route?.includes("political-candidates-and_issues") ? true : false,
      // active: globalSettings?.menu?.political ? globalSettings?.menu?.political?.value : false,
      icon: GavelIcon,
      subNav: [{
        name: "Manage Orders",
        src: "internal",
        href: "/political-candidates-and-issues/matching/",
        active: true,
        icon: AccountTreeIcon,
        badge: politicalMatchingCount
      }, {
        name: "By Advertiser",
        src: "internal",
        href: "/political-candidates-and-issues/advertisers/",
        active: true,
        indent: true,
        icon: HorizontalRuleIcon,
      }, {
        name: "By AE",
        src: "internal",
        href: "/political-candidates-and-issues/aes/",
        active: true,
        indent: true,
        icon: HorizontalRuleIcon,
      }, {
        name: "FCC Docs",
        src: "internal",
        href: "/political-candidates-and-issues/report/",
        active: true,
        icon: AssessmentIcon
      }, {
        name: "Terms & Disclosures",
        src: "internal",
        href: "/political-candidates-and-issues/terms-and-disclosures/",
        active: true,
        icon: GavelIcon
      }, {
        name: "Activity Report",
        src: "internal",
        href: "/political-candidates-and-issues/activity/",
        active: true,
        icon: SsidChartIcon
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/political-candidates-and-issues/queue/",
        active: true,
        icon: ViewListIcon
      }]
    }, {
      name: "Issues & Programs",
      src: "internal",
      href: "/issues-and-programs/",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access?.includes("Issues & Programs") ? true : false,
      icon: DriveFolderUploadIcon,
      subNav: [{
        name: "Full Report",
        src: "internal",
        href: "/issues-and-programs/report/",
        active: true,
        icon: AssessmentIcon
      }, {
        name: "Compliance Report",
        src: "internal",
        href: "/issues-and-programs/compliance/",
        active: true,
        icon: CrisisAlertIcon
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/issues-and-programs/queue/",
        active: true,
        icon: ViewListIcon
      }]
    }, {
      name: "EEO",
      src: "internal",
      href: "/equal-employment-opportunity",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access?.includes("EEO (Equal Employment Opportunity)") && globalSettings?.menu?.eeo && globalSettings?.menu?.eeo?.value || allUserSettings?.access?.includes("EEO (Equal Employment Opportunity)") && props.route?.includes("equal-employment-opportunity") ? true : false,
      icon: PolicyIcon,
      subNav: [{
        name: "Full Report",
        src: "internal",
        href: "/equal-employment-opportunity/report/",
        active: true,
        icon: AssessmentIcon
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/equal-employment-opportunity/queue/",
        active: true,
        icon: ViewListIcon
      }]

    }, {
      name: "Applications",
      src: "internal",
      href: "/applications",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access?.includes("Applications") && globalSettings?.menu?.applications && globalSettings?.menu?.applications?.value || allUserSettings?.access?.includes("Applications") && props.route?.includes("applications") ? true : false,
      icon: EventNoteIcon
    }, {
      name: "Entity Settings",
      src: "internal",
      href: "/entity-settings",
      active: false,
      icon: SettingsApplicationsIcon
    }],
    SecondaryMenuItems: MenuItem[] = [{
      name: "Get Support",
      src: "external",
      active: true,
      href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/",
      icon: ContactSupportIcon
    }, {
      name: "Submit An Idea",
      src: "external",
      active: true,
      href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?req=feature",
      icon: TipsAndUpdatesIcon
    }, {
      name: "Training Library",
      src: "internal",
      active: true,
      href: "/training/",
      icon: VideoLibraryIcon
    }, {
      name: "Mobile App",
      src: "internal",
      active: false,
      href: "/mobile-app",
      icon: AppShortcutIcon
    }];

  const DrawerList = (
    <Box sx={{...classes.drawerPaper, width: 250, marginTop: "100px"}} role="presentation">
      <List>
        {MainMenuItems.map((item) => {
          let expand = false;

          // If the menu item has subnav menu items
          if (item.subNav) {
            const subNavItems: any = [];
            // Put the subnav items into an array
            item.subNav.map((subNav) => {
              const route = props?.route?.split("/")[1];

              expand = props?.route !== "/" && subNav?.href && subNav?.href?.includes(route) ? true : false;
              subNavItems.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <ListItem key={subNav.name} disablePadding component={Link} disabled={!subNav.active}
                  to={`${subNav.href}`}>
                  <ListItemButton
                    sx={{...classes.listItemStyles, pl: 2}}
                    dense={true}
                    selected={props?.route?.includes(subNav.href)}>
                    {subNav.icon ?
                      <ListItemIcon sx={{...classes.listItemIcon, marginLeft: subNav?.indent ? "30px" : undefined}}>
                        {subNav.badge && subNav.badge >= 1 ? <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          overlap="rectangular"
                          badgeContent={subNav.badge ? subNav.badge : 0}
                          color="warning"
                          sx={{paddingRight: "5px", paddingTop: "3px"}}
                        >
                          <subNav.icon/>
                        </Badge>
                          : <subNav.icon/>}
                      </ListItemIcon> : <ListItemIcon sx={classes.listItemIcon}></ListItemIcon>}
                    <ListItemText primary={subNav.name} sx={classes.listItemText}/>
                  </ListItemButton>
                </ListItem>
              );
            });

            const [openIssues, setOpenIssues] = useState(false);
            const updateOpenIssuesMenu = () => {
              setOpenIssues(!openIssues);
            };

            // return the menu item and its subnav items
            // Logic previously leveraged openIssues line 290 and 288
            return (<Suspense key={item.name} fallback={<div key={item.name} style={{
              height: "100px",
              backgroundColor: "transparent"
            }}></div>}>
              <ListItemButton id={item.name + "123123"} sx={classes.listItemStyles} disabled={!item.active} dense={true}
                onClick={updateOpenIssuesMenu}>
                {item?.icon ? <ListItemIcon sx={classes.listItemIcon}>
                  <item.icon/>
                </ListItemIcon> : <></>}
                <ListItemText primary={item.name} sx={classes.listItemText}/>
                {path.includes(item.href) || openIssues ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
              </ListItemButton>
              <Collapse in={path.includes(item.href) || openIssues} timeout="auto"
                aria-expanded={path.includes(item.href) || openIssues}
                mountOnEnter unmountOnExit>
                <List disablePadding>
                  {subNavItems}
                </List>
              </Collapse>
            </Suspense>);
          }

          // Regular menu item
          else {

            return (<ListItem key={item.name} disablePadding disabled={!item.active}>
              <ListItemButton sx={classes.listItemStyles} dense={true}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Link}
                to={item.active ? item.href : undefined}
                selected={props?.route?.split("/")[1] === item.href || props?.route === item.href}>
                {item?.icon ? <ListItemIcon sx={classes.listItemIcon}>
                  <item.icon/>
                </ListItemIcon> : <></>}
                <ListItemText primary={item.name} sx={classes.listItemText}/>
              </ListItemButton>
            </ListItem>);

          }
        })
        }
      </List>
      <Divider sx={classes.dividerStyleCb}/>
      <List>
        {SecondaryMenuItems.map((item) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <ListItem key={item.name} disablePadding component={Link}
            target={item.src === "external" ? "_blank" : undefined}
            to={item.src === "external" && item.active ? item.href : (item.src !== "external" && item.active) ? `${item.href}` : undefined}>
            <ListItemButton sx={classes.secondaryBtn} dense={true} disabled={!item.active}>
              {item?.icon ? <ListItemIcon sx={classes.listItemIcon}>
                <item.icon/>
              </ListItemIcon> : <></>}
              <ListItemText primary={item.name} sx={classes.listItemText}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>)
  ;
  return (
    <Suspense fallback={<div style={{...classes.loading_drawer as any}}></div>}>
      <Box>
        {/*<Button onClick={toggleDrawer(true)}>Open drawer</Button>*/}
        <Drawer
          // onClose={toggleDrawer(false)}
          anchor={"left"}
          variant={isSmallScreen ? "temporary" : "permanent"}
          // open={isSmallScreen ? open : true}
          open={true}
          sx={classes.body}
        >
          {DrawerList}
        </Drawer>
      </Box>
    </Suspense>
  );
};