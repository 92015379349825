import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAtom} from "jotai";
import {Facility} from "pages/facility/index";
import {Facilities} from "pages/facilities/index";
import SsoLogout from "pages/auth/Logout/index";
import SsoLogin from "pages/auth/SsoLogin/index";
import {Markets} from "pages/markets/index";
import {Market} from "pages/market/index";
import {FccIssuesAndPrograms} from "pages/fcc_issues_and_programs/pages/full_report";
import {FccIssuesAndProgramsQueue} from "pages/fcc_issues_and_programs/pages/upload_queue";

import {FccEeoReport} from "pages/fcc_equal_employment_opportunities/pages/full_report";
import {FccEeoQueue} from "pages/fcc_equal_employment_opportunities/pages/upload_queue";
import {FccEeoBatchQueue} from "pages/fcc_equal_employment_opportunities/pages/upload_batch_status";
import {FccEeoDocumentUploadStatus} from "pages/fcc_equal_employment_opportunities/pages/document_upload_status";
import {FccEeoDocumentUploadStatusByBlog} from "pages/fcc_equal_employment_opportunities/pages/upload_document_status";
import {EeoPdf} from "pages/fcc_equal_employment_opportunities/pages/document_view";


import {FccIssuesAndProgramsBatchQueue} from "pages/fcc_issues_and_programs/pages/upload_batch_status";
import {FccIssuesAndProgramsComplianceReport} from "pages/fcc_issues_and_programs/pages/compliance_report";
import {FccPoliticalAdvertisers} from "pages/fcc_political_candidates_and_issues/pages/advertisers";
import {FccPoliticalAdvertiser} from "pages/fcc_political_candidates_and_issues/pages/advertiser";
import {FccPoliticalAes} from "pages/fcc_political_candidates_and_issues/pages/aes";
import {FccPoliticalAe} from "pages/fcc_political_candidates_and_issues/pages/ae";
import {FccPoliticalFullReport} from "pages/fcc_political_candidates_and_issues/pages/full_report";
import {FccPoliticalMatching} from "pages/fcc_political_candidates_and_issues/pages/matching";
import {FccPoliticalQueue} from "pages/fcc_political_candidates_and_issues/pages/upload_queue";
import {FccPoliticalBatchQueue} from "pages/fcc_political_candidates_and_issues/pages/upload_batch_status";
import {FccPoliticalDocumentUploadStatus} from "pages/fcc_political_candidates_and_issues/pages/upload_document_status";
import {FccPoliticalComplianceReport} from "pages/fcc_political_candidates_and_issues/pages/activity_report";
import {FccPoliticalUpload} from "pages/fcc_political_candidates_and_issues/pages/upload_documents";
import {FccPoliticalBulkUpload} from "pages/fcc_political_candidates_and_issues/pages/upload_documents_batch";
import {FccPoliticalBulkForcedMatching} from "pages/fcc_political_candidates_and_issues/pages/matching_forced_form";
import {FccPoliticalUploadAny} from "pages/fcc_political_candidates_and_issues/pages/upload_documents_any";
import {FccPoliticalTermsAndDisclosures} from "pages/fcc_political_candidates_and_issues/pages/terms_and_disclosures";
import {
  FccPoliticalUploadTermsAndDisclosures
} from "pages/fcc_political_candidates_and_issues/pages/upload_documents_terms_and_disclosures";


import {Pdf} from "pages/pdf/index";
import {PoliticalPdf} from "pages/fcc_political_candidates_and_issues/pages/document_view";
import {Profile} from "pages/profile/index";
import {Training} from "pages/training/index";
import React, {lazy, Suspense, useEffect} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {useStyles} from "./styles/styles.js";
import {Status} from "pages/status/index";
import NotFound from "pages/not_found";
import {LeftNav} from "components/nav/desktop/components/left_nav/index";
import {TopAppBar} from "components/nav/desktop/components/top_nav/index";
import {MobileMenu} from "components/nav/mobile/index";
import {cachedRequests} from "services/api/cachedRequests";
import {v4 as uuidv4} from "uuid";

const
  Dashboard = lazy(() => import("pages/home/index").then((module) => ({default: module["Dashboard"]}))),
  Login = lazy(() => import("pages/auth/index")),
  Onboarding = lazy(() => import("pages/onboarding/index")),
  Box = lazy(() => import("@mui/material/Box"));

export default function AllRoutes(props: any) {
  const
    classes = useStyles(),
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    // [authToken] = useAtom(authTokenAtom);
    [userInfo] = useAtom<any>(userInfoAtom),
    location = useLocation(),
    navigate = useNavigate(),
    {cachedSettingsData} = cachedRequests();

  useEffect(() => {
    cachedSettingsData();
  }, []);


  // Prevent users from being able to bypass onboarding if they hit direct links to non-onboarding screens
  useEffect(() => {
    const generalLogic = userInfo && userInfo.isMsUser === true && location && location.pathname && location.pathname !== "/msauth/login" && location.pathname !== "/msauth/callback";
    if (generalLogic) {
      if (!userInfo.completedOnboarding) {
        navigate("/onboarding");
      } else if (location.pathname.includes("onboarding") && userInfo.completedOnboarding) {
        navigate("/");
      }
    }
  }, [userInfo]);


  // Redirect the user to logout if their auth token has expired
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!authToken && userInfo.firstName !== "" && !window.location.pathname.includes("/msauth/")) {
  //       navigate("/msauth/logout");
  //     }
  //   }, 2000);
  // }, [authToken, window.location.pathname]);


  // Routes with menus and content sections
  const routes: ({
    component: any;
    path: string,
    menuRoute: string,
    title: null | string,
    breadCrumb: null | any | [];
  })[] = [{
    path: "/",
    menuRoute: "/",
    component: Dashboard,
    title: "Home",
    breadCrumb: ["Home"],
  }, {
    path: "/training/",
    menuRoute: "/training/",
    component: Training,
    title: "Training Library",
    breadCrumb: ["Home", "Training Library"],
  }, {
    path: "/profile",
    menuRoute: "/profile",
    component: Profile,
    title: "Profile",
    breadCrumb: ["Home", "Profile"],
  },
    // - - - - - - - - - - - - - - - - - -
    // Facilities
    // - - - - - - - - - - - - - - - - - -
  {
    path: "/facilities/",
    menuRoute: "/facilities/",
    component: Facilities,
    title: "Facilities",
    breadCrumb: ["Home", "Facilities"],
  }, {
    path: "/facility/:id/",
    menuRoute: "/facilities/",
    component: Facility,
    title: "Facility",
    breadCrumb: ["Home", "Facility"],
  },
    // - - - - - - - - - - - - - - - - - -
    // Markets
    // - - - - - - - - - - - - - - - - - -
  {
    path: "/markets/",
    menuRoute: "/markets/",
    component: Markets,
    title: "Markets",
    breadCrumb: ["Home", "Markets"],
  }, {
    path: "/market/:name/",
    menuRoute: "/markets/",
    component: Market,
    title: "Market",
    breadCrumb: ["Home", "Market"],
  },
    // - - - - - - - - - - - - - - - - - -
    // Political Candidates and Issues
    // - - - - - - - - - - - - - - - - - -
  {
    path: "/political-candidates-and-issues/report/",
    menuRoute: "/political-candidates-and-issues/report/",
    component: FccPoliticalFullReport,
    title: "",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/ae/:name",
    menuRoute: "/political-candidates-and-issues/aes/",
    component: FccPoliticalAe,
    title: "",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/aes/",
    menuRoute: "/political-candidates-and-issues/aes/",
    component: FccPoliticalAes,
    title: "",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/advertiser/:name",
    menuRoute: "/political-candidates-and-issues/advertisers/",
    component: FccPoliticalAdvertiser,
    title: "",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/advertisers/",
    menuRoute: "/political-candidates-and-issues/advertisers/",
    component: FccPoliticalAdvertisers,
    title: "",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/activity/",
    menuRoute: "/political-candidates-and-issues/activity/",
    component: FccPoliticalComplianceReport,
    title: "Activity Report",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/matching/",
    menuRoute: "/political-candidates-and-issues/matching/",
    component: FccPoliticalMatching,
    title: "Political Files Matching",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/queue/",
    menuRoute: "/political-candidates-and-issues/queue/",
    component: FccPoliticalQueue,
    title: "Political Files Queue",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "political-candidates-and-issues/queue/:id",
    menuRoute: "political-candidates-and-issues/queue/",
    component: FccPoliticalBatchQueue,
    title: "Political Files Batch Queue",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/upload/",
    menuRoute: "/political-candidates-and-issues/queue/",
    component: FccPoliticalBulkUpload,
    title: "Political Bulk Files Uploader",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/upload/:id",
    menuRoute: "/political-candidates-and-issues/matching/",
    component: FccPoliticalUpload,
    title: "Political Files Uploader",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/uploader/",
    menuRoute: "/political-candidates-and-issues/report/",
    component: FccPoliticalUploadAny,
    title: "Political Files Uploader",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/queue/:id/facility/:facid/document/:document",
    menuRoute: "/political-candidates-and-issues/queue/",
    component: FccPoliticalDocumentUploadStatus,
    title: "Political Files Queue",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/document/:id",
    menuRoute: "/political-candidates-and-issues/report/",
    component: PoliticalPdf,
    title: "Political Files Viewer",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/political-candidates-and-issues/forced-matching/",
    menuRoute: "/political-candidates-and-issues/matching/",
    component: FccPoliticalBulkForcedMatching,
    title: "",
    breadCrumb: ["Home", ""],
  }, {
    path: "/political-candidates-and-issues/terms-and-disclosures/",
    menuRoute: "/political-candidates-and-issues/terms-and-disclosures/",
    component: FccPoliticalTermsAndDisclosures,
    title: "",
    breadCrumb: ["Home", ""],
  }, {
    path: "/political-candidates-and-issues/terms-and-disclosures-uploader/",
    menuRoute: "/political-candidates-and-issues/terms-and-disclosures/",
    component: FccPoliticalUploadTermsAndDisclosures,
    title: "",
    breadCrumb: ["Home", ""],
  },
    // - - - - - - - - - - - - - - - - - -
    // Issues and Programs
    // - - - - - - - - - - - - - - - - - -
  {
    path: "/issues-and-programs/queue/",
    menuRoute: "/issues-and-programs/queue/",
    component: FccIssuesAndProgramsQueue,
    title: "Queue",
    breadCrumb: ["Home", "Issues & Programs"],
  }, {
    path: "/issues-and-programs/report/",
    menuRoute: "/issues-and-programs/report/",
    component: FccIssuesAndPrograms,
    title: "Report",
    breadCrumb: ["Home", "Issues & Programs"],
  }, {
    path: "/issues-and-programs/compliance/",
    menuRoute: "/issues-and-programs/compliance/",
    component: FccIssuesAndProgramsComplianceReport,
    title: "Compliance Report",
    breadCrumb: ["Home", "Issues & Programs"],
  }, {
    path: "/issues-and-programs/queue/:id",
    menuRoute: "/issues-and-programs/queue/",
    component: FccIssuesAndProgramsBatchQueue,
    title: "Upload Queue",
    breadCrumb: ["Home", "Issues & Programs"],
  }, {
    path: "/issues-and-programs/queue/:id/facility/:facid",
    menuRoute: "/issues-and-programs/queue/",
    component: Status,
    title: "Upload Queue",
    breadCrumb: ["Home", "Issues & Programs"],
  }, {
    path: "/issues-and-programs/document/:id",
    menuRoute: "/issues-and-programs/document/:id",
    component: Pdf,
    title: "Document Viewer",
    breadCrumb: ["Home", "Issues & Programs"],
  },
    // - - - - - - - - - - - - - - - - - -
    // Equal Employment Opportunity
    // - - - - - - - - - - - - - - - - - -
  {
    path: "/equal-employment-opportunity/report/",
    menuRoute: "/equal-employment-opportunity/report/",
    component: FccEeoReport,
    title: "Report",
    breadCrumb: ["Home", "Equal Employment Opportunity"],
  },
  {
    path: "/equal-employment-opportunity/queue/",
    menuRoute: "/equal-employment-opportunity/queue/",
    component: FccEeoQueue,
    title: "Queue",
    breadCrumb: ["Home", "Equal Employment Opportunity"],
  }, {
    path: "/equal-employment-opportunity/queue/:id",
    menuRoute: "/equal-employment-opportunity/queue/",
    component: FccEeoBatchQueue,
    title: "Upload Queue",
    breadCrumb: ["Home", "Equal Employment Opportunity"],
  }, {
    path: "/equal-employment-opportunity/queue/:id/blog/:blogid",
    menuRoute: "/equal-employment-opportunity/queue/",
    component: FccEeoDocumentUploadStatus,
    title: "Upload Queue",
    breadCrumb: ["Home", "Equal Employment Opportunity"],
  }, {
      // /equal-employment-opportunity/queue/d5aff527ef844fb5940db343ed624609/blog/143/document/a26e9fc78ad678137ea42923a4de4bea
    path: "/equal-employment-opportunity/queue/:id/blog/:blogid/document/:document",
    menuRoute: "/equal-employment-opportunity/queue/",
    component: FccEeoDocumentUploadStatusByBlog,
    title: "Political Files Queue",
    breadCrumb: ["Home", "Political"],
  }, {
    path: "/equal-employment-opportunity/document/:id",
    menuRoute: "/equal-employment-opportunity/report/",
    component: EeoPdf,
    title: "EEO Files Viewer",
    breadCrumb: ["Home", "Equal Employment Opportunity"],
  },
    // - - - - - - - - - - - - - - - - - -
    // 404
    // - - - - - - - - - - - - - - - - - -
  {
    path: "/*",
    menuRoute: "/*",
    component: NotFound,
    title: "404",
    breadCrumb: ["Home"],
  }];

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route key={"health"} path={"/health"} element={<div>ok</div>}/>
        <Route key={"login"} path={"/msauth/login"} element={<Login/>}/>
        <Route key={"logincallback"} path={"/msauth/callback"} element={<SsoLogin/>}/>
        <Route key={"logout"} path={"/msauth/logout"} element={<SsoLogout/>}/>
        <Route key={"onboarding"} path={"/onboarding"} element={<Onboarding {...{mobile: isSmallScreen}}/>}/>

        <Route key={"political-candidates-and-issues"} path={"/political-candidates-and-issues/"}
          element={<Navigate to="/political-candidates-and-issues/matching/" replace/>}/>

        {routes.map((route: {
            path: string,
            menuRoute: string,
            title: string | null,
            breadCrumb: any,
            component: any,
          }, index: number) => {
          return (
            <Route key={uuidv4()} path={route.path} element={
              <>
                {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
                {isSmallScreen ?
                  <main>
                    <route.component {...{mobile: isSmallScreen}}/>
                  </main> :
                  <main style={{display: "flex", position: "relative"}}>
                    <LeftNav {...{route: route.menuRoute}} />
                    <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                      <route.component {...{mobile: isSmallScreen}}/>
                    </Box>
                  </main>}
              </>
            }/>
          );
        }
        )};
      </Routes>
    </Suspense>
  );
}


// <Route key={"404"}
//        path={"/*"} element={<>
//   {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
//   {isSmallScreen ?
//     <main>
//       <NotFound {...{mobile: isSmallScreen}} />
//     </main> :
//     <main style={{display: "flex", position: "relative"}}>
//       <LeftNav {...{route: ""}} />
//       <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
//         <NotFound {...{mobile: isSmallScreen}} />
//       </Box>
//     </main>}
// </>}
// />
{/*{routes.map((route: {*/
}
{/*  title: string | null,*/
}
{/*  breadCrumb: any,*/
}
{/*  component: any,*/
}
{/*  route: string;*/
}
{/*}, index: number) => {*/
}
{/*  return (*/
}
{/*    <Route key={uuidv4()} path={route.route} element={*/
}
{/*      <>*/
}
{/*        {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*        {isSmallScreen ?*/
}
{/*          <main>*/
}
{/*            <Container {...{*/
}
{/*              ...route,*/
}
{/*              screen: isSmallScreen,*/
}
{/*            }} />*/
}
{/*          </main> :*/
}
{/*          <main style={{display: "flex", position: "relative"}}>*/
}
{/*            <LeftNav {...{route: route.route}} />*/
}
{/*            <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*              <Container {...{*/
}
{/*                ...route,*/
}
{/*                screen: isSmallScreen,*/
}
{/*              }} />*/
}
{/*            </Box>*/
}
{/*          </main>}*/
}
{/*      </>*/
}
{/*    }/>*/
}
{/*  );*/
}
{/*})}*/
}

{/*<Route key={uuidv4()} path={"/profile"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Profile {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/profile"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Profile {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*{*/
}
{/*  route: "/issues-and-programs/document",*/
}
{/*  component: Pdf,*/
}
{/*  title: " ",*/
}
{/*  breadCrumb: ["Home", ":id"],*/
}
{/*},*/
}
{/*<Route key={uuidv4()} path={"/issues-and-programs/document/:id"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Pdf {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/issues-and-programs/document/:id"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Pdf {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*{*/
}
{/*  route: "/facility/:id/",*/
}
{/*  component: Facility,*/
}
{/*  title: " ",*/
}
{/*  breadCrumb: ["Home", ":id"],*/
}
{/*},*/
}
{/*<Route key={uuidv4()} path={"/facility/:id/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Facility {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/facilities/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Facility {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}


{/*<Route key={uuidv4()} path={"/facilities/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Facilities {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/facilities/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Facilities {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*<Route key={uuidv4()} path={"/markets/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Markets {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/markets/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Markets {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}


{/*<Route key={uuidv4()} path={"/market/:name/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Market {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/markets/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Market {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}


{/*  {*/
}
{/*    route: "",*/
}
{/*    component: Dashboard,*/
}
{/*    title: `${userInfo?.firstName}'s Dashboard`,*/
}
{/*    breadCrumb: ["Home", "Dashboard"]*/
}
{/*  }, {*/
}
{/*  route: "/",*/
}
{/*  component: Dashboard,*/
}
{/*  title: `${userInfo?.firstName}'s Dashboard`,*/
}
{/*  breadCrumb: ["Home", "Dashboard"]*/
}
{/*},*/
}
{/*<Route key={uuidv4()} path={"/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Dashboard {...{user: userInfo, mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Dashboard {...{user: userInfo, mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}


{/*{*/
}
{/*  route: "/issues-and-programs/status/:id",*/
}
{/*  component: Status,*/
}
{/*  title: "Upload",*/
}
{/*  breadCrumb: ["Home", "issues-and-programs/queue/", ":id"]*/
}
{/*}*/
}
{/*<Route key={uuidv4()} path={"/issues-and-programs/queue/:id/facility/:facid"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <Status {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/issues-and-programs/queue/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <Status {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*{*/
}
{/*  route: "/issues-and-programs/queue/:id",*/
}
{/*  component: FccIssuesAndProgramsBatchQueue,*/
}
{/*  title: "Upload",*/
}
{/*  breadCrumb: ["Home", "issues-and-programs/queue/", ":id"]*/
}
{/*},*/
}
{/*<Route key={uuidv4()} path={"/issues-and-programs/queue/:id"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccIssuesAndProgramsBatchQueue {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/issues-and-programs/queue/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccIssuesAndProgramsBatchQueue {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}


{/*<Route key={uuidv4()} path={"/issues-and-programs/compliance/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccIssuesAndProgramsComplianceReport {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/issues-and-programs/compliance/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccIssuesAndProgramsComplianceReport {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*{*/
}
{/*  route: "/issues-and-programs/report/",*/
}
{/*  component: FccIssuesAndPrograms,*/
}
{/*  title: "Issues And Programs",*/
}
{/*  breadCrumb: ["Home", "Issues And Programs"],*/
}
{/*},*/
}
{/*<Route key={uuidv4()} path={"/issues-and-programs/report/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccIssuesAndPrograms {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/issues-and-programs/report/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccIssuesAndPrograms {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*<Route key={uuidv4()} path={"/issues-and-programs/queue/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccIssuesAndProgramsQueue {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/issues-and-programs/queue/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccIssuesAndProgramsQueue {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/*{*/
}
{/*  route: "/issues-and-programs/compliance/",*/
}
{/*  component: FccIssuesAndProgramsComplianceReport,*/
}
{/*  title: "Issues And Programs",*/
}
{/*  breadCrumb: ["Home", "Issues And Programs"],*/
}
{/*},*/
}


{ /* - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          Political candidates and issues routes
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/activity/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalComplianceReport {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/activity/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalComplianceReport {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/advertisers/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalAdvertisers {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/advertisers/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalAdvertisers {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/advertiser/:name"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalAdvertiser {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/advertisers/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalAdvertiser {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/aes/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalAes {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/aes/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalAes {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/ae/:name"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalAe {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/aes/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalAe {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/report/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalFullReport {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/report/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalFullReport {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/matching/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalMatching {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/matching/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalMatching {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/queue/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalQueue {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/queue/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalQueue {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/queue/:id"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalBatchQueue {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/queue/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalBatchQueue {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/* BULK UPLOAD USING IDS FROM LOCAL STORAGE */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/upload/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalBulkUpload {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/matching/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalBulkUpload {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/* SINGLE UPLOADS USING A CSV ID */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/upload/:id"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalUpload {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/matching/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalUpload {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/* WILD WEST UPLOADS OF ANYTHING */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/uploader/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalUploadAny {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/report/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalUploadAny {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/queue/:id/facility/:facid/document/:document"}*/
}
{/*  element={*/
}
{/*    <>*/
}
{/*      {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*      {isSmallScreen ?*/
}
{/*        <main>*/
}
{/*          <FccPoliticalDocumentUploadStatus {...{mobile: isSmallScreen}}/>*/
}
{/*        </main> :*/
}
{/*        <main style={{display: "flex", position: "relative"}}>*/
}
{/*          <LeftNav {...{route: "/political-candidates-and-issues/queue/"}} />*/
}
{/*          <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*            <FccPoliticalDocumentUploadStatus {...{mobile: isSmallScreen}}/>*/
}
{/*          </Box>*/
}
{/*        </main>}*/
}
{/*    </>*/
}
{/*  }/>*/
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/document/:id"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <PoliticalPdf {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/report/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <PoliticalPdf {...{mobile: isSmallScreen}} />*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}

{/* BULK FORCED MATCHING */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/forced-matching/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalBulkForcedMatching {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/matching/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalBulkForcedMatching {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/* TERMS AND DISCLOSURES */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/terms-and-disclosures/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalTermsAndDisclosures {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/terms-and-disclosures/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalTermsAndDisclosures {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{/* WILD WEST UPLOADS OF ANYTHING */
}
{/*<Route key={uuidv4()} path={"/political-candidates-and-issues/terms-and-disclosures-uploader/"} element={*/
}
{/*  <>*/
}
{/*    {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/
}
{/*    {isSmallScreen ?*/
}
{/*      <main>*/
}
{/*        <FccPoliticalUploadTermsAndDisclosures {...{mobile: isSmallScreen}}/>*/
}
{/*      </main> :*/
}
{/*      <main style={{display: "flex", position: "relative"}}>*/
}
{/*        <LeftNav {...{route: "/political-candidates-and-issues/terms-and-disclosures/"}} />*/
}
{/*        <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*          <FccPoliticalUploadTermsAndDisclosures {...{mobile: isSmallScreen}}/>*/
}
{/*        </Box>*/
}
{/*      </main>}*/
}
{/*  </>*/
}
{/*}/>*/
}
{ /* - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          Dynamic routes that require the app bar and the side nav
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
}

{/*{routes.map((route: {*/
}
{/*  title: string | null,*/
}
{/*  breadCrumb: any,*/
}
{/*  component: any,*/
}
{/*  route: string;*/
}
{/*}, index: number) => {*/
}

{/*  return (*/
}
{/*    <Route key={index} path={route.route} element={*/
}
{/*      <>*/
}
{/*        /!*{isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*!/*/
}
{/*        {isSmallScreen ?*/
}
{/*          <Container {...{*/
}
{/*            ...route,*/
}
{/*            screen: isSmallScreen,*/
}
{/*          }} /> :*/
}
{/*          <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/
}
{/*            <Container {...{*/
}
{/*              ...route,*/
}
{/*              screen: isSmallScreen,*/
}
{/*            }} />*/
}
{/*          </Box>*/
}
{/*        }*/
}
{/*      </>*/
}
{/*    }/>*/
}
{/*  );*/
}
{/*})}*/
}
