import React, {lazy, Suspense, useEffect, useState} from "react";
import {NavLink as Link, useMatch} from "react-router-dom";
import {useDate} from "hooks/useDate";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import urlPaths from "services/api/urlPaths";
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {v4 as uuidv4} from "uuid";
import {useAtom} from "jotai";
import {cachedRequests} from "services/api/cachedRequests";

const
  Title = lazy(() => import("components/ui/pagetitle").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found")),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  Brands = lazy(() => import("components/ui/table/components/cell/Brands").then((module) => ({default: module["Brands"]}))),
  StepStatus = lazy(() => import("components/ui/table/components/cell/Status").then((module) => ({default: module["Status"]}))),
  StatusStepper = lazy(() => import("pages/fcc_equal_employment_opportunities/components/Stepper").then((module) => ({default: module["StatusStepper"]}))),
  StatusStepperBlogs = lazy(() => import("pages/fcc_equal_employment_opportunities/components/StepperBlogs").then((module) => ({default: module["StatusStepperBlogs"]}))),
  // StatusStepper = lazy(() => import("pages/fcc_political_candidates_and_issues/components/Stepper").then((module) => ({default: module["StatusStepper"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Avatar = lazy(() => import("@mui/material/Avatar")),
  AvatarGroup = lazy(() => import("@mui/material/AvatarGroup")),
  Box = lazy(() => import("@mui/material/Box")),
  Stack = lazy(() => import("@mui/material/Stack"));

export const FccEeoDocumentUploadStatus = (props: any) => {
  const
    {cachedMarketsData} = cachedRequests(),
    theme = useTheme(),
    {convertDateAndTimeStamp} = useDate(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    issuesQueueFacilityPath = "/equal-employment-opportunity/queue/:id/blog/:blogid/document/:document",
    id = useMatch(issuesQueueFacilityPath)?.params.id,
    blogId = useMatch(issuesQueueFacilityPath)?.params.blogid,
    documentId = useMatch(issuesQueueFacilityPath)?.params.document,
    [topLevelData, setTopLevelData] = useState<any>(),
    [documentName, setDocumentName] = useState(""),
    [elapsedTime, setElapsedTime] = useState<string>("0 minutes"),
    [statusName, setStatusName] = useState(""),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Fetching and using our data
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    issueUploadStatusSummary = requestAtom(`politicalUploadStatusSummary`, `${urlPaths.EEO_REPORT_STATUS_SUMMARY}?ui_batch_id=${id}&file_id=${documentId}`, {}),
    issueUploadStatus = requestAtom(`politicalUploadStatus`, `${urlPaths.EEO_REPORT_STATUS}?ui_batch_id=${id}&file_id=${documentId}`, {}, setErrorState),
    [politicalSummaryState, setIssuesSummaryState] = useState<any>(() => issueUploadStatusSummary),
    [politicalUploadTableState, setIssuesUploadTableState] = useState<any>(() => issueUploadStatus),
    [{data: summaryData, isPending: summaryPending, isError: summaryError}] = useAtom<AtomQuery>(politicalSummaryState),
    [{data: tableData, isPending: tablePending, isError: tableError}] = useAtom<AtomQuery>(politicalUploadTableState),
    [sortedTableData, setSortedTableData] = useState([]),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Control states
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    [fccDirectory, setFccDirectory] = useState<string>(""),
    [pageIs404, setPageIs404] = useState<any>(false),
    [refetchButton, setRefetchButton] = useState({display: true, disabled: true}),
    [refetch, setRefetch] = useState(true),
    [getData, setGetData] = useState<any>();

  // Get the markets data once from local storage or the server
  useEffect(() => {
    cachedMarketsData();
  }, []);

  // Initial get and set of the data
  useEffect(() => {
    setIssuesSummaryState(() => issueUploadStatusSummary);
    setIssuesUploadTableState(() => issueUploadStatus);
  }, []);

  useEffect(() => {
    if (summaryError || tableError) {
      setRefetch(false);
      setPageIs404(true);
    }
  }, [summaryError, tableError]);

  // Once table data is available manipulate it
  useEffect(() => {
    if (tableData?.items?.length > 0) {
      const brandItems: any = [];
      tableData?.items?.map((item: any) => {
        if (blogId && item.result.blog_id === blogId.toString()) {
          brandItems.push(item);
        }
        if (item.status === "file is live") {
          setRefetch(false);
        }
      });
      if (brandItems.length > 0) {
        setSortedTableData(brandItems.reverse());
      }
    }
  }, [tableData]);

  // Once summary data is available manipulate it
  useEffect(() => {
    if (summaryData?.summary) {
      if (summaryData?.summary?.percentage === 100) {
        setRefetch(false);
        // setControl(true);
      }
      setRefetchButton(summaryData?.summary?.percentage === 100 || summaryData?.summary?.last_status_message === "verification process completed" ? {
        display: false,
        disabled: false
      } : {display: true, disabled: true});
      setStatusName(summaryData?.summary.last_status_message);
      summaryData.summary.file_name = summaryData?.summary?.document_name;
      summaryData.summary.document_name = summaryData?.summary?.candidate_campaign_name;
      if (documentId && summaryData?.summary) {
        const markets: { facility_id: string, name: string, callsign: string, blog_id: string }[] = [];
        summaryData.summary.market.map((market: {
          facility_id: string,
          name: string,
          callsign: string,
          blog_id: string
        }) => {
          if (market.blog_id === blogId) {
            markets.push(market);
          }
        });
        summaryData.summary.market = markets;
        summaryData.summary.results.map((result: {
          facility_id: string,
          blog_id: string,
          containing_folder_link: string
        }) => {
          if (result.blog_id === blogId && result.containing_folder_link) {
            setFccDirectory(result.containing_folder_link);
          }
        });
      }
      setTopLevelData(summaryData?.summary);
      setDocumentName(summaryData?.summary?.candidate_campaign_name);

      setElapsedTime(summaryData?.summary.elapsed_time_string);
      setStatusName(summaryData?.summary.last_status_message);
      setBreadCrumb(["Home", "issues-and-programs/queue/", summaryData?.summary?.candidate_campaign_name]);
    }
  }, [summaryData]);

  // Everytime getData is reset update the summary table data
  useEffect(() => {
    setIssuesSummaryState(() => issueUploadStatusSummary);
    setIssuesUploadTableState(() => issueUploadStatus);
  }, [getData]); // Empty dependency array ensures useEffect runs only once on mount

  // Timed loop that executes so long the control state remains False
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let totalTime = 0;
    const loopDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

    if (refetch) {
      setRefetchButton({display: true, disabled: true});
      intervalId = setInterval(() => {
        if (totalTime >= loopDuration) {
          setRefetch(false);
          setRefetchButton({display: true, disabled: false});
          clearInterval(intervalId);
        } else {
          setGetData(new Date().toISOString());
          totalTime += 5000; // Increase total time by 5 seconds
        }
      }, 5000); // 5 seconds interval
    }
    return () => clearInterval(intervalId); // Cleanup on component unmount or control state change
  }, [refetch]);


  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Upload Status",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          status_type: string,
          status_date: number,
          status: string,
          callsigns: string[],
          message: string,
          result: {
            facility_id: string,
            callsign: string,
            service: string,
            image: string,
            brand_name: string | null,
            internal_doc_link?: string,
            public_doc_link?: string,
            containing_folder_link?: string
          }[]
        }
      }) => {
        return <Grid key={uuidv4()} container spacing={4}>
          <Grid xs={12} sx={{marginBottom: "10px"}}>
            <Stack direction="row" spacing={2}
              sx={{lineHeight: "normal", marginTop: "5px", width: "fit-content", textWrap: "wrap"}}>
              <AvatarGroup max={6}>
                {params?.row?.result.map((result: { callsign: string, image: string }) =>
                  <Avatar key={uuidv4()} sx={{boxShadow: 4}} alt={result.callsign}
                    src={result.image}/>)
                }
              </AvatarGroup>
              <StepStatus {...{
                ...props,
                type: "status",
                mobile: false,
                value: params.row.status
              }} />
            </Stack>
            <Stack direction="column" spacing={.5}
              sx={{lineHeight: "normal", marginTop: "5px", width: "fit-content", textWrap: "wrap"}}>
              <span>Type: {params.row.status_type}</span>
              <span>Date: {params.row.status_date}</span>
              <span>Status: {params.row.status}</span>
              <span>Message: {params.row.message}</span>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    columns = [{
      field: "status_date",
      id: "status_date",
      headerName: "Upload Date",
      maxWidth: 200,
      flex: 1,
      renderCell: (params: { row: { status_date: string } }) => {
        return <p>{convertDateAndTimeStamp(params.row.status_date)}</p>;
      }
    },
    {
      field: "message",
      id: "message",
      headerName: "Status",
      flex: 1,
      width: 200,
      renderCell: (params: { row: { message: string } }) => {
        return <StepStatus {...{
          ...props,
          type: "status",
          mobile: false,
          value: params.row.message
        }} />;
      }
    }, {
      field: "result",
      id: "result",
      headerName: "Results",
        // maxWidth: 250,
        // align: "right",
      flex: 1,
      cellClassName: "modifiedDate",
      renderCell: (params: {
          row: {
            upload_filename: string,
            facility_id: string,
            service: string,
            callsign: string,
            market: string,
            link: string,
            result: {
              public_doc_link: string,
              internal_doc_link: string,
            },
            name: string,
            status: string,
            upload_date: string,
            date_required: string,
            file_id: string,
            upload_status: string,
            upload_process_status_id: any,
            containing_folder_link: string
          }
        }) => {
        if (params?.row?.status?.includes("is live")) {
          console.log("row", params.row);
          return <details>
            <summary>
                View Upload Results
            </summary>
            <p>
              {<Stack spacing={0} key={uuidv4()}>
                <Link to={`/political-candidates-and-issues/document/${params.row.file_id}`} target={"_blank"}
                  rel="noreferrer">View {params.row.callsign} File</Link>
                <Link to={params.row.result.public_doc_link} target={"_blank"}
                  rel="noreferrer">Download {params.row.callsign} File</Link>
                <Link to={fccDirectory} target={"_blank"} rel="noreferrer">View FCC
                    Directory</Link>
              </Stack>}
            </p>
          </details>;
        } else {
          return <p>-</p>;
        }
      }
    }, {
      field: "brands",
      id: "brands",
      headerName: "Brands",
      width: 150,
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
          row: { result: { facility_id: string, callsign: string, service: string, image: string, brand_name: any } }
        }) => {
        return <Brands {...{
          results: [{
            facility_id: params.row.result.facility_id,
            callsign: params.row.result.callsign,
            image: params.row.result.image,
          }]
        }
        } />;
      }
    }],
    topperProps: any = {
      title: summaryData?.summary?.file_name,
      inputs: [{
        label: "Refresh",
        type: "button",
        disabled: refetchButton.disabled,
        display: refetchButton.display,
        loader: true,
        onClick: ((e: React.MouseEvent) => {
          setRefetch(true);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      {!pageIs404 ? <><Title {...{
        topLevelData: {
          screen: "queue",
          ...topLevelData
        },
        title: "Upload For ",
        video: {
          title: "Upload Queue",
          src: "/craft/rest/training/Political-UploadQueue.mp4"
        },
        mobile: props.mobile,
      }} />
      <Breadcrumb {...{
        breadcrumb: breadcrumb
      }} />
      <Box sx={{backgroundColor: "transparent", margin: props.screen ? "10px" : undefined, boxShadow: 4}}>
        <Topper {...{...topperProps}} />
        <Box sx={{
          width: "100%",
          backgroundColor: "#e0f7fa",
          zIndex: 10,
          padding: "20px 0px 0px 0px",
          position: "relative"
        }}>
          <Stack direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0} sx={{margin: "0 auto"}}
          >
            <StatusStepper {...{status: statusName}}/>
            <StatusStepperBlogs {...{status: statusName}}/>
            <p style={{fontSize: "12px", fontWeight: "400", marginTop: "20px"}}><strong>Time
                Elapsed:</strong> {elapsedTime}</p>
          </Stack>
        </Box>

        <Table {...{
          data: sortedTableData ? sortedTableData : [],
          pageSize: 20,
          columns: props.screen ? mobileColumns : columns,
          selector: "status_date",
          sortBy: "status_date",
          loading: tablePending,
          error: tableError,
          errorMessage: errorState
        } as any} />
      </Box></> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're having difficulties locating this upload process."
      }} />}
    </Suspense>
  );
};