import axios from "axios";
import {useAtom} from "jotai";
import {useNavigate} from "react-router-dom";
import {authTokenAtom} from "../jotai/auth/msauth";

const PORT = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? ":3220" : "";
export const BASE_URL = `//${window.location.hostname}${PORT}`;

export const useApiAuth = () => {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useAtom(authTokenAtom);

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
  });

  axiosInstance.interceptors.request.use((config) => {

    if (authToken) {
      config.headers["Authorization"] = `${authToken}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      const prevToken = axiosInstance.defaults.headers.common["Authorization"];
      const currToken = response.headers.jwt;

      if (prevToken !== currToken && currToken) {
        // Update authToken atom and Axios instance default header
        setAuthToken(currToken);
        axiosInstance.defaults.headers.common["Authorization"] = currToken;
      }
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        console.log("Unauthorized error, handle logout potentially", authToken, error);
        setAuthToken("");
        navigate("/msauth/logout");
      }
      throw error;
    });

  const makeFormRequest = async (method: any, url: any, data: any = null, params: any = null) => {
    try {
      const formDataHeaders = {"Content-Type": "multipart/form-data"};

      const bodyFormData = new FormData();

      bodyFormData.append("market", data.market.toString());
      bodyFormData.append("year", data.year.toString());
      data.quarter && bodyFormData.append("quarter", data.quarter);
      data.ui_batch_id && bodyFormData.append("ui_batch_id", data.ui_batch_id.toString());
      bodyFormData.append("is_revision", data.is_revision);
      bodyFormData.append("filename", data.filename);
      data.facility_ids?.forEach((facility_id: string, index: number) => {
        bodyFormData.append(`facility_ids[${index}]`, facility_id.toString());
      });
      bodyFormData.append("file", data.file);
      axios.defaults.headers["Content-Type"] = "multipart/form-data";

      const response = await axiosInstance({
        method,
        url,
        data: bodyFormData,
        // ...formDataHeaders,
        headers: formDataHeaders
      });
      return response.data;
    } catch (error) {
      console.log("log", error);
      // navigate("/msauth/logout");
      throw error; // Re-throw the error for handling in the component
    }
  };

  const makePoliticalFileUploadRequest = async (method: any, url: any, data: any = null, params: any = null) => {
    try {
      const formDataHeaders = {"Content-Type": "multipart/form-data"};

      const bodyFormData = new FormData();
      bodyFormData.append("ui_batch_id", data.ui_batch_id.toString());
      bodyFormData.append("fcc_path", data.fcc_path.toString());

      // bodyFormData.append("market", data.market.toString());
      bodyFormData.append("year", data.year.toString());
      bodyFormData.append("candidate_campaign_name", data.candidate_campaign_name.toString());
      // bodyFormData.append("office_type", data.office_type.toString());
      bodyFormData.append("facility_id", data.facility_id.toString());
      // bodyFormData.append("filename", data.filename);
      bodyFormData.append("file", data.file);

      bodyFormData.append("candidate_type", data.candidate_type.toString());
      bodyFormData.append("region", data.region.toString());
      bodyFormData.append("office_name", data.office_name.toString());
      bodyFormData.append("custom_folder_path", data.custom_folder_path.toString());


      axios.defaults.headers["Content-Type"] = "multipart/form-data";

      const response = await axiosInstance({
        method,
        url,
        data: bodyFormData,
        // ...formDataHeaders,
        headers: formDataHeaders
      });
      return response.data;
    } catch (error) {
      console.log("log", error);
      // navigate("/msauth/logout");
      throw error; // Re-throw the error for handling in the component
    }
  };

  const makeSaveProfileRequest = async (method: any, url: any, data: any = null, params: any = null) => {
    try {
      console.log("makeSaveProfileRequest", data.access);
      const formDataHeaders = {"Content-Type": "multipart/form-data"};

      const bodyFormData = new FormData();

      bodyFormData.append("userId", data.userId);
      data.firstName ? bodyFormData.append("firstName", data.firstName) : undefined;
      data.lastName ? bodyFormData.append("lastName", data.lastName) : undefined;
      data.email ? bodyFormData.append("email", data.email) : undefined;
      data.primaryMarket ? bodyFormData.append("primaryMarket", data.primaryMarket) : undefined;
      data.secondaryMarkets ? bodyFormData.append("secondaryMarkets", JSON.stringify(data.secondaryMarkets)) : undefined;
      // data.secondaryMarkets ? bodyFormData.append("secondaryMarkets", JSON.parse(JSON.stringify(data.secondaryMarkets))) : undefined;
      data.access ? bodyFormData.append("access", JSON.stringify(data.access)) : undefined;
      data.persistTables ? bodyFormData.append("persistTables", data.persistTables) : undefined;
      data.timezone ? bodyFormData.append("timezone", data.timezone) : undefined;
      data.file ? bodyFormData.append("file", data.file) : undefined;
      data.completedOnboarding ? bodyFormData.append("completedOnboarding", data.completedOnboarding) : false;

      axios.defaults.headers["Content-Type"] = "multipart/form-data";

      const response = await axiosInstance({
        method,
        url,
        data: bodyFormData,
        // ...formDataHeaders,
        headers: formDataHeaders
      });
      return response.data;
    } catch (error) {
      console.log("log", error);
      // navigate("/msauth/logout");
      throw error; // Re-throw the error for handling in the component
    }
  };

  const makeRequestAlternative = async (method: any, url: any, data: any = null, params: any = null) => {
    try {
      const formDataHeaders = {"Content-Type": "multipart/form-data"};
      const bodyFormData = new FormData();


      Object.keys(data).forEach(function (key, index) {
        console.log("makeRequestAlternative", key, data[key]);
        bodyFormData.append(key, data[key]);
      });
      axios.defaults.headers["Content-Type"] = "multipart/form-data";

      const response = await axiosInstance({
        method,
        url,
        ...bodyFormData,
        headers: formDataHeaders
      });
      return response.data;
    } catch (error) {
      console.log("makeRequestAlternative", error);
      throw error; // Re-throw the error for handling in the component
    }
  };

  const makeRequestServerSent = async (method: any, url: any, data: any = null, params: any = null) => {
    try {
      url = (data) ? `${url}?${new URLSearchParams(data).toString()}` : url;
      console.log("axios starting", url);

      axiosInstance.defaults.headers.get["Accept"] = "*/*";
      axiosInstance.defaults.headers.get["Connection"] = "keep-alive";
      axiosInstance.defaults.responseType = "stream"; // 'arraybuffer', 'document', 'json', 'text', 'stream', 'blob'
      // axiosInstance.defaults.adapter = "xhr";
      const response = await axiosInstance({
        method,
        url,
        data,
        params,
      });

      console.log("axios got a response", response);

      // const stream = response.data;
      // console.log("stream", stream);

      // stream.on("data", (data: any) => {
      //   console.log(data);
      //   return data;
      // });
      //
      // stream.on("end", () => {
      //   console.log("stream done");
      // });

      // consume response
      // const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
      // console.log("reader", reader);
      //
      // const run = true;
      // while (run) {
      //   const {value, done} = await reader.read();
      //   if (done) break;
      //   console.log("value", value);
      // }
      // return response.data;
    } catch (error) {
      console.log("log", error);
      throw error; // Re-throw the error for handling in the component
    }
  };

  const makeRequest = async (method: any, url: any, data: any = null, params: any = null) => {
    try {
      url = (data) ? `${url}?${new URLSearchParams(data).toString()}` : url;
      const response = await axiosInstance({
        method,
        url,
        data,
        params,
      });
      return response.data;
    } catch (error) {
      console.log("log", error);
      // navigate("/msauth/logout");
      throw error; // Re-throw the error for handling in the component
    }
  };

  return {
    makeRequest,
    makeRequestServerSent,
    makeRequestAlternative,
    makeFormRequest,
    makeSaveProfileRequest,
    makePoliticalFileUploadRequest,
  };

};