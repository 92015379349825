import React, {lazy, Suspense, useEffect} from "react";
import {cachedRequests} from "services/api/cachedRequests";
import {FccEeoUploadQueue} from "../components/tables/upload_queue";

const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index"));

export const FccEeoQueue = (props: any) => {
  const
    {cachedMarketsData} = cachedRequests(),
    buttonBarButons: any = {
      left: [],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/equal-employment-opportunity/queue/"
      }]
    };

  useEffect(() => {
    cachedMarketsData();
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Equal Employment Opportunity",
        video: {
          title: "Upload Queue",
          src: "/craft/rest/training/Political-UploadQueue.mp4"
        },
        mobile: props.mobile,
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Equal Employment Opportunity", "Upload Queue"]
      }} />
      <ButtonBar {...buttonBarButons} />
      <FccEeoUploadQueue {...{...props, showFccLink: false}} />
    </Suspense>
  );
};
