// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {firstNameAtom, userInfoAtom} from "services/jotai/auth/msauth";
import {NotificationCenter} from "../../../notifications/index";
import {Avatar} from "@mui/material";
import Box from "@mui/material/Box";
import {useAtom} from "jotai";
import React, {lazy, Suspense, useCallback} from "react";
import {NavLink as Link, useNavigate} from "react-router-dom";
import logo from "../../../../../assets/signalcraft-logo-darkBG.png";
import {useStyles} from "./styles/styles.js";
import LogoutIcon from "@mui/icons-material/Logout";

const AppBar = lazy(() => import("@mui/material/AppBar"));
const Button = lazy(() => import("@mui/material/Button"));
const Typography = lazy(() => import("@mui/material/Typography"));
const Toolbar = lazy(() => import("@mui/material/Toolbar"));
const Popover = lazy(() => import("@mui/material/Popover"));
const Stack = lazy(() => import("@mui/material/Stack"));
const SettingsIcon = lazy(() => import("@mui/icons-material/Settings"));
export const TopAppBar = (props: any) => {
  const
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    [firstName] = useAtom(firstNameAtom);

  const
    classes = useStyles(),
    navigate = useNavigate(),

    [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null),

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },

    handleClose = () => {
      setAnchorEl(null);
    },

    stringToColor = (string: string) => {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
      return color;
    },

    stringAvatar = (name: string) => {
      return {
        sx: {
          height: "30px",
          width: "30px",
          fontSize: "15px",
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1] ? name.split(" ")[1][0] : null}`,
      };
    },

    handleLogout = useCallback(async () => {
      navigate("/msauth/logout");
    }, []);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Suspense fallback={<div style={{...classes.loading_toolbar} as any}></div>}>
      <nav style={{...classes.nav as any}}>
        <AppBar sx={classes.appbar} position="fixed">
          <Toolbar sx={classes.toolbar} variant="dense">
            <Link to={"/"}>
              <img style={{...classes.logo}}
                src={logo}/>
            </Link>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <div>
                <Button aria-describedby={id} variant="contained" onClick={handleClick}
                  sx={{
                    boxShadow: 0,
                    backgroundColor: "transparent",
                    "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}
                  }}
                >
                  <Avatar alt={`${userInfo.firstName} ${userInfo.lastName}`}
                    {...stringAvatar(`${userInfo.firstName} ${userInfo.lastName}`)}
                    src={`/craft/rest/users/avatar/${userInfo.username}`}
                    sx={{
                      boxShadow: 9,
                      height: "30px",
                      width: "30px",
                      fontSize: "14px",
                      bgcolor: stringToColor(`${userInfo.firstName} ${userInfo.lastName}`)
                    }}/>
                  <Typography variant="h6" color="inherit"
                    style={{color: "white", fontSize: "12px", textDecoration: "none", marginLeft: "10px"}}>
                    {(userInfo.firstName) ? userInfo.firstName : firstName + " " + userInfo?.lastName}
                  </Typography>
                </Button>
                <Popover
                  sx={classes.userProfilePopover}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Stack direction="column" spacing={2}>
                    <Button
                      sx={{color: "#ffa400 !important", fontSize: "12px"}}
                      onClick={handleLogout}
                      startIcon={<LogoutIcon sx={{height: "15px", fontSize: "14px", marginRight: "2px"}}/>}
                    >LOGOUT</Button>
                  </Stack>
                </Popover>
              </div>
              <NotificationCenter {...{userInfo: userInfo, type: "desktop"}}/>
              <Button
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Link}
                to={"/profile/"}
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  fontSize: "12px",
                  "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}
                }}
                startIcon={<SettingsIcon sx={{height: "18px", marginRight: "-4px"}}/>}
              >Settings</Button>
            </Box>
          </Toolbar>
        </AppBar>
      </nav>
    </Suspense>
  );
};