import React, {lazy, Suspense, useEffect} from "react";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import Avatar from "@mui/material/Avatar";
import {useDate} from "hooks/useDate";
import {v4 as uuidv4} from "uuid";
import {NavLink as Link} from "react-router-dom";

const
  Typography = lazy(() => import("@mui/material/Typography")),
  // Box = lazy(() => import("@mui/material/Box")),
  Stack = lazy(() => import("@mui/material/Stack"));

export default function StatusDetails(props: any) {
  const
    status = props.summary ? props.summary : props,
    {convertDatePretty} = useDate(),
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),

    Line = styled(Typography)`
        margin: 0px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: 11px;
        //line-height: 1.334;
        //letter-spacing: 0em;
    `;
  // Image = styled("img")`
  //   line-height: normal;
  //   height: auto;
  //   width: auto;
  //   max-height: 100px;
  //   max-width: 200px;
  //   margin-bottom: 15px;
  //   //boxShadow: 5;
  // `;

  // useEffect(() => {
  //   console.log("status details", props);
  // }, []);


  const stringAvatar = (callsign: string) => {
    return {
      sx: {
        height: "30px",
        width: "30px",
        fontSize: "15px",
      },
      children: `${callsign}`,
    };
  };
  const getBrandAvatar = (facility_id: string, callsign: string, service: string) => {
    return <Link key={uuidv4()} to={`/facility/${facility_id}/`}>
      <Avatar
        key={uuidv4()}
        alt={`${callsign} Logo`}
        {...stringAvatar(`${callsign}`)}
        src={`https://publicfiles.fcc.gov/api/manager/download/entity/logo/${facility_id}/${service.toLowerCase()}`}
        // src={`/craft/rest/image?facility_id=${params.row.facility_id}`}
        sx={{width: 50, height: 50, boxShadow: 2, fontSize: "10px", fontColor: "white", backgroundColor: "#010159"}}
      />
    </Link>;
  };

  const getBrandItems = () => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const facilityId = urlParams.get("facility_id");
    const facilityId = window?.location?.pathname.includes("/document/") && window?.location?.pathname.includes("/facility/") ? window?.location?.pathname?.split("facility/")[1].split("/document")[0] : window?.location?.pathname?.split("facility/")[1];
    if (!facilityId) {
      return props?.results?.map((result: {
        facility_id: string,
        callsign: string,
        service: string,
        image: string,
        brand_name: string,
        containing_folder_link: string
      }) => {
        return (<Stack sx={{alignItems: "center", padding: "10px"}} direction="row" spacing={2} key={uuidv4()}>
          {/*<Avatar sx={{boxShadow: 4}} alt={result.callsign} src={result.image}/>*/}
          {getBrandAvatar(result.facility_id, result.callsign, result.service)}
          <Stack direction="column" spacing={0}>
            <Line variant={"h6"}><strong>Callsign: </strong><Link
              to={`/facility/${result.facility_id}/`}>{result.callsign}</Link></Line>
            <Line variant={"h6"}><strong>Service: </strong>{result.service}</Line>
            <Line variant={"h6"}><strong>Facility ID: </strong><Link
              to={`/facility/${result.facility_id}/`}>{result.facility_id}</Link></Line>
            <Line variant={"h6"}><Link to={result.containing_folder_link}><strong>FCC Folder
              Link</strong></Link></Line>
          </Stack>
        </Stack>);
      });
    } else {
      // const items: any = [];
      // for (let i = 0; i < 6; i++) {
      //   props?.results?.map((result: {
      //     facility_id: string,
      //     callsign: string,
      //     service: string,
      //     image: string,
      //     brand_name: string,
      //     containing_folder_link: string,
      //   }) => {
      //     if (result.facility_id === facilityId) {
      //       items.push(<Stack sx={{alignItems: "center", padding: "10px"}} direction="row" flexWrap="wrap" spacing={2}
      //         key={result.callsign}>
      //         {getBrandAvatar(result.facility_id, result.callsign, result.service)}
      //         <Stack direction="column" spacing={0}>
      //           <Line variant={"h6"}><strong>Callsign: </strong><Link
      //             to={`/facility/${result.facility_id}/`}>{result.callsign}</Link></Line>
      //           <Line variant={"h6"}><strong>Service: </strong>{result.service}</Line>
      //           <Line variant={"h6"}><strong>Facility ID: </strong><Link
      //             to={`/facility/${result.facility_id}/`}>{result.facility_id}</Link></Line>
      //           <Line variant={"h6"}><Link to={result.containing_folder_link}><strong>FCC Folder
      //             Link</strong></Link></Line>
      //         </Stack>
      //       </Stack>);
      //     } else {
      //       return <></>;
      //     }
      //   });
      // }
      // return items;
      return props?.results?.map((result: {
        facility_id: string,
        callsign: string,
        service: string,
        image: string,
        brand_name: string,
        containing_folder_link: string,
      }) => {
        if (result.facility_id === facilityId) {
          return (
            <Stack sx={{alignItems: "center", padding: "10px"}} direction="row" flexWrap="wrap" spacing={2}
              key={uuidv4()}>
              {getBrandAvatar(result.facility_id, result.callsign, result.service)}
              <Stack direction="column" spacing={0}>
                <Line variant={"h6"}><strong>Callsign: </strong><Link
                  to={`/facility/${result.facility_id}/`}>{result.callsign}</Link></Line>
                <Line variant={"h6"}><strong>Service: </strong>{result.service}</Line>
                <Line variant={"h6"}><strong>Facility ID: </strong><Link
                  to={`/facility/${result.facility_id}/`}>{result.facility_id}</Link></Line>
                <Line variant={"h6"}><Link to={result.containing_folder_link}><strong>FCC Folder
                  Link</strong></Link></Line>
              </Stack>
            </Stack>);
        } else {
          return <></>;
        }
      });
    }
  };

  const arr = Array.isArray(status.market) ? status.market : status?.market?.split(",");
  const markets: string[] = [];
  status?.market && Array.isArray(status.market) ? status?.market && Array.isArray(status.market) && status?.market?.length > 0 ? arr.map((market: any, index: number) => {
    if (!markets.includes(market.name)) {
      markets.push(market.name);
    }
  }) : arr && arr?.length > 0 ? arr.map((market: any, index: number) => {
    if (!markets.includes(market.name)) {
      markets.push(market.name);
    }
  }) : null : null;
  useEffect(() => {
    console.log("markets", markets);
  }, [markets]);
  return (
    <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={150}/>}>
      <Grid container spacing={2} sx={{paddingTop: "20px", width: "100%"}}>
        <Grid xs={12} sm={12} md={12} lg={4} spacing={4}>
          <Stack spacing={0} sx={{width: "max-content"}}>
            <Line variant={"h6"}><strong>Uploaded:</strong> {convertDatePretty(status.date)}</Line>
            {status.quarter ? <Line variant={"h6"}><strong>Quarter:</strong> Q{status.quarter}</Line> : <></>}
            <Line variant={"h6"}><strong>Year:</strong> {status.year}</Line>
            <Line
              variant={"h6"}><strong>Market(s):</strong> {status?.market && status?.market?.length > 0 && markets && markets?.length > 0 ? markets?.map((market: any, index: number) => {
                return <Link key={uuidv4()}
                  to={`/market/${market}/`}>{index > 0 && index < arr?.length ? ", " : null} {market}</Link>;
              }) : <Link to={`/market/${status.market}/`}>{status.market}</Link>}
            </Line>

            {/*<Line*/}
            {/*  variant={"h6"}><strong>Market:</strong> {Array.isArray(status.market) ? status.market.length > 0 ? arr.map((market: any, index: number) =>*/}
            {/*    <Link key={uuidv4()}*/}
            {/*      to={`/market/${market.name}/`}>{index > 0 && index < arr.length ? ", " : null} {market.name}</Link>) :*/}
            {/*    <Link to={`/market/${status.market}/`}>{status.market}</Link>*/}
            {/*    : arr?.length > 0 ? arr.map((market: string, index: number) =>*/}
            {/*      <Link key={uuidv4()}*/}
            {/*        to={`/market/${market}/`}>{index > 0 && index < arr.length ? ", " : null} {market}</Link>) :*/}
            {/*      <Link to={`/market/${status.market}/`}>{status.market}</Link>}*/}
            {/*</Line>*/}
            <Line variant={"h6"}><strong>Uploader:</strong> {status.uploaded_by}</Line>
            <Line variant={"h6"}><strong>Process ID:</strong> {status.upload_process_status_id}</Line>
          </Stack>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={8}>
          <Stack direction="row" flexWrap="wrap" spacing={0}
            sx={{marginTop: "-15px", marginLeft: "20px", width: "auto", maxWidth: "fit-content"}}>
            {getBrandItems()}
          </Stack>
        </Grid>
      </Grid>
    </Suspense>
  );
}