import Skeleton from "@mui/material/Skeleton";
import {persistTablePagination} from "hooks/persistTablePagination";
import {StickyTable} from "./index";
import React, {lazy, Suspense, useEffect, useState} from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const
  Paper: any = lazy(() => import("@mui/material/Paper")),
  LinearProgress: any = lazy(() => import("@mui/material/LinearProgress"));


interface Column {
  id: string;
  headerName: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  sortable?: boolean;
}

interface Row {
  id: number;

  [key: string]: any;
}

type Order = "asc" | "desc";

export const CollapseTable = (props: any) => {

  const
    [openRow, setOpenRow] = useState<number | null>(null),
    [order, setOrder] = useState<Order>("asc"),
    // [orderBy, setOrderBy] = useState<string>(""),
    [orderBy, setOrderBy] = useState<string>(props.tableSortBy || ""),
    [page, setPage] = useState(0),
    [rowsPerPage, setRowsPerPage] = useState(props?.pageSize || 20),
    {
      getTableObject,
      resetTable,
      setTablePageSize,
      getTablePageSize,
      setTablePageIndex,
      getTablePageIndex
    } = persistTablePagination(props.tableKey),
    [paginationModel, setPaginationModel] = useState({pageSize: 0, page: 0}),
    // getPaginationModel = (data: any) => {
    //   if (props.tableKey) {
    //     setPaginationModel({pageSize: data.pageSize, page: data.page});
    //
    //     setTablePageSize(data.pageSize);
    //     setTablePageIndex(data.page);
    //   }
    // },
    // resetPaginationToOne = () => {
    //   setPaginationModel({pageSize: getTablePageSize(), page: 0});
    // },
    [showLoading, setShowLoading] = useState(true);

  const handleExpandClick = (id: number) => {
    setOpenRow(openRow === id ? null : id);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
    setTablePageIndex(0);
  };

  const sortComparator = (a: Row, b: Row, orderBy: string) => {
    if (!orderBy && a[orderBy] < b[orderBy]) return order === "desc" ? 1 : -1;
    if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  };

  const handleChangePage = (event: any, newPage: number) => {
    setTablePageIndex(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTablePageSize(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedRows = orderBy ? [...props.data].sort((a, b) => sortComparator(a, b, orderBy)) : props?.data;

  const paginatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    console.log("resetting pagination", props.resetPagination);
    setPage(0);
    resetTable();
  }, [props.resetPagination]);

  useEffect(() => {
    if (props.pageSize) {
      setTablePageSize(props.pageSize);
    }
    setRowsPerPage(Number(getTablePageSize()));
    if (!props.pending) {
      // setPaginationModel({pageSize: Number(getTablePageSize()), page: Number(getTablePageIndex())});
      setShowLoading(true);

      const timeout = setTimeout(() => {
        setShowLoading(false);
      }, 1000);

      if (props.data && props.data.length >= 1) {
        setShowLoading(false);
        clearTimeout(timeout);
      }

      return () => clearTimeout(timeout);
    }
  }, [props.data]);


  return (
    <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width="100%" height={400}/>}>
      <TableContainer
        component={Paper}
        sx={{
          // padding: props?.mobile ? "0 10px" : undefined,
          width: props?.mobile ? "auto" : "auto",
          height: "fit-content",
          // maxWidth: props?.mobile ? "fit-content" : "none",

          // width: "min-content",
          // minWidth: "auto",
          // position: props?.mobile ? "inherit" : undefined,
          margin: props?.mobile ? "0 10px" : undefined,
          // marginTop: props?.mobile ? "0 !important" : undefined,
          // marginLeft: props?.mobile ? "0 !important" : undefined,
          // padding: props?.mobile ? "0 10px" : undefined

        }}
        elevation={props?.mobile ? 3 : 1}
      >
        <Table size="small">
          <TableHead sx={{backgroundColor: "#e0f7fa", height: 39, padding: "0px 16px !important"}}>
            <TableRow sx={{height: 39, padding: "0px 16px !important"}}>
              {props.columns.map((column: any) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,

                    // maxWidth: column?.maxWidth ? column?.maxWidth : undefined,
                    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                    fontWeight: 500,
                    color: "#424242!important",
                    lineHeight: "1.5rem",
                    fontSize: "1.1rem",
                    fontVariant: "all-small-caps",
                    fontStyle: "uppercase",
                    letterSpacing: "0.01071em",
                    padding: "0px 16px !important"
                  }}
                >
                  {!column.sortable && column.sortable !== false ? (
                    <TableSortLabel
                      // active={orderBy === column.id}
                      active={!orderBy && props.tableSortBy === column.field || orderBy === column.id}
                      direction={!orderBy ? "asc" : orderBy === column.id ? order : "asc"}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.headerName}
                    </TableSortLabel>
                  ) : (
                    column.headerName
                  )}
                </TableCell>
              ))}
              <TableCell sx={{
                // minWidth: column.minWidth,
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                fontWeight: 500,
                color: "#424242!important",
                lineHeight: "1.5rem",
                fontSize: "1.1rem",
                fontVariant: "all-small-caps",
                fontStyle: "uppercase",
                letterSpacing: "0.01071em",
                padding: "0px 16px !important"
              }}>Expand</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row: any, index: number) => (
              <React.Fragment key={row.id}>
                <TableRow
                  style={{
                    backgroundColor: (page * rowsPerPage + index) % 2 === 0 ? "white" : "#f5f5f5",
                    padding: "0px 16px !important"
                  }}
                >
                  {props.columns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        // maxWidth: column?.maxWidth ? column?.maxWidth : undefined,
                        // padding: "0px 16px !important"
                        padding: props?.mobile ? "10px 16px !important" : "0px 16px !important"
                      }}>
                      {column.renderCell ? column.renderCell(row) : row[column.id]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton onClick={() => handleExpandClick(row.id)}>
                      {openRow === row.id ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{paddingBottom: 0, paddingTop: 0}}
                >
                  <TableCell
                    colSpan={props.columns?.length + 1}
                    style={{paddingBottom: 0, paddingTop: 0}}
                  >
                    <Collapse
                      in={openRow === row.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        {!props.subTableComponent && props.subTableColumns ? <StickyTable
                          {...{
                            tableKey: "general",
                            mobile: props.mobile,
                            data: row[props.subTableKey] ? row[props.subTableKey] : [], // tableData && tableData?.length > 0 ? tableData : [],
                            columns: props.subTableColumns,
                            loading: false,
                            error: false,
                            errorMessage: null,
                            sortBy: props?.subTableSortBy ? props.subTableSortBy : null
                            // expandedRows: null
                          }} /> : props.subTableComponent && !props.subTableColumns ? <props.subTableComponent
                          props={row ? row : []}/> : <></>}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {props.pending && <LinearProgress/>}
        <TablePagination
          // page: props.tableKey ? getTablePageIndex() : 0,
          // rowsPerPage: props.pageSize? props.pageSize : 10,
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          component="div"
          count={props.data.length}
          // rowsPerPage={paginationModel.pageSize}

          rowsPerPage={rowsPerPage}
          // rowsPerPage={props.tableKey && getTablePageSize() ? getTablePageSize() : props.pageSize ? props.pageSize : 10}
          // page={page}
          page={props.tableKey ? getTablePageIndex() : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Suspense>
  );
};