import React from "react";
import Avatar from "@mui/material/Avatar";
import {Link} from "react-router-dom";

export const Brand = (props: {
  callsign: string,
  facility_id: string,
  service: string,
  size?: number,
  styles?: any
}) => {
  const stringAvatar = (callsign: string) => {
    return {
      sx: {
        height: "30px",
        width: "30px",
        fontSize: "15px",
      },
      children: `${callsign}`,
    };
  };

  return <Link to={`/facility/${props.facility_id}/`}>
    <Avatar
      alt={`${props.callsign} Logo`}
      {...stringAvatar(`${props.callsign}`)}
      src={`/craft/rest/image?facility_id=${props.facility_id}`}
      sx={{
        width: props.size ?? 50,
        height: props.size ?? 50,
        boxShadow: 2,
        fontSize: "13px",
        fontColor: "white",
        backgroundColor: "#010159",
        ...props?.styles
      }}
    />
  </Link>;
};